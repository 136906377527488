import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addToFavorites,
  searchVets,
  validateZipCode,
  vetZipCodeChanged,
  cleanMyVetsErrors,
  searchPrimaryVet,
} from '../../../actions/myVets';
import close from '../../../assets/close.svg';
import { validateNumbersOnly } from '../../../services/utils';
import { EXPLORE_PROVIDERS } from '../../../constants';
import ModalContainer from '../../common/ModalContainer';
import LoadingButton from '../../common/LoadingButton';
import { FoundVetItem } from './FoundVetItem';
import { SearchInput } from './SearchInput';
import { AddVetManuallyModal } from './AddVetManuallyModal';
import './AddVetModal.css';

/**
 * @@deprecated
 *  Remove with PetCloudManualVetFlow
 */
const AddVetModal = ({
  customVetShowCancelButton = false,
  displaySelectOnRows = false,
  hideZipcode = false,
  onClose = () => { },
  selectedVet = null,
  setAddVetModalOpen = () => { },
  onSelectedVet = () => { },
  primaryVet = false,
}) => {
  const store = useSelector(({ personalInformation, myVets }) => ({
    ...myVets,
    userZipCode: personalInformation.zipCode,
  }), shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation('myPets');
  const [zipCode, setZipCode] = useState(store.userZipCode);
  const [willChangeZipCode, setWillChangeZipCode] = useState(false);
  const [zipCodeError, setZipCodeError] = useState('');
  const [isManuallyModalOpen, setIsManuallyModalOpen] = useState(false);

  const {
    addVetError,
    favoriteVets,
    isEditingZipCode,
    isLoadingVets,
    isSavingVet,
    isValidatingZipCode,
    isValidZipCode,
    lastZipcodeLoaded,
    userZipCode,
    vetSaved,
    vetsFound,
  } = store;

  useEffect(() => {
    if (!isValidatingZipCode) {
      if (zipCodeError === '' && !isValidZipCode
        && !isEditingZipCode) {
        setZipCodeError(t('MyVets.addVetModal.errorZipCode'));
      }

      if (isValidZipCode && !isEditingZipCode) {
        setWillChangeZipCode(false);
      }
    }
  }, [t, isValidZipCode, isEditingZipCode, zipCodeError, isValidatingZipCode]);

  useEffect(() => {
    if (zipCode.length < 5 || lastZipcodeLoaded === zipCode) {
      return;
    }

    if (primaryVet) {
      dispatch(searchPrimaryVet({ zipCode }));
    } else {
      dispatch(searchVets({ zipCode }));
    }
  }, [dispatch, zipCode]);

  function selectItem(item) {
    onSelectedVet(item);
  }

  function switchToChangeZipCode() {
    setWillChangeZipCode(true);
  }

  function handleClose() {
    onClose();
    dispatch(cleanMyVetsErrors());
    onSelectedVet(null);
    setWillChangeZipCode(false);
    setZipCode(userZipCode);
  }

  function handleCancel() {
    if (willChangeZipCode) {
      setWillChangeZipCode(false);
      return;
    }

    if (!isSavingVet || !isValidatingZipCode) {
      handleClose();
    }
  }

  function handleSaveChangeZipCode() {
    if (zipCode.length < 5) {
      setZipCodeError(t('MyVets.addVetModal.errorDigits'));
      return;
    }

    dispatch(validateZipCode({ zipCode }));
    setZipCodeError('');
  }

  function handleSave() {
    if (!willChangeZipCode && selectedVet) {
      dispatch(addToFavorites({
        categoryId: 1,
        placeId: selectedVet.PlaceId,
        providerId: EXPLORE_PROVIDERS.google,
      }));
      return;
    }

    handleSaveChangeZipCode();
  }

  function handleOnKeyDown(event) {
    const { keyCode } = event;
    const ENTER_KEY = 13;

    if (keyCode === ENTER_KEY) {
      handleSaveChangeZipCode();
    }
  }

  useEffect(() => {
    if (vetSaved && selectedVet) {
      handleClose();
    }
  }, [vetSaved]);

  function renderFoundItem(item) {
    return (
      <FoundVetItem
        key={item.PlaceId || item.Id}
        added={favoriteVets
          .find((vet) => vet.PlaceId === item.PlaceId)}
        addedLabel={t('MyVets.addVetModal.added')}
        address={item.Address}
        distance={item.Distance}
        name={item.Title || item.Name}
        rightText={displaySelectOnRows ? t('MyVets.addVetModal.select') : ''}
      />
    );
  }

  function handleOpenAddVetManuallyModal() {
    setIsManuallyModalOpen(true);
  }

  function handleCloseAddVetManuallyModal() {
    setIsManuallyModalOpen(false);
    setAddVetModalOpen(true);
  }

  function handleSuccesCustomVetModal() {
    setIsManuallyModalOpen(false);
    onClose();
  }

  const renderNotListedMessage = () => (
    <>
      <span className="Add-vet-modal-not-listed">
        {t('MyVets.addVetModal.notListed')} &nbsp;
      </span>

      <button
        className="Like-span Add-vet-modal-add-manually"
        onClick={handleOpenAddVetManuallyModal}
        type="button"
      >
        {t('MyVets.addVetModal.enterManually')}
      </button>
    </>
  );

  function renderSelectedItem() {
    return (
      <div className="Add-vet-modal-selected-vet">
        <span className="Add-vet-modal-vet-name">
          {selectedVet.Title || selectedVet.Name}
        </span>

        <span className="Add-vet-modal-vet-address">{selectedVet.Address}</span>
      </div>
    );
  }

  function handleChangeZipCode(event) {
    const { value } = event.target;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');
    setZipCode(onlyNumbersValue);

    if (onlyNumbersValue.length === 5) {
      setZipCodeError('');
    }

    dispatch(vetZipCodeChanged());
  }

  function handleEmptyItemsFiltered(query) {
    if (primaryVet) {
      dispatch(searchPrimaryVet({ query, zipCode }));
    } else {
      dispatch(searchVets({ query, zipCode }));
    }
  }

  return (
    <>
      <ModalContainer show>
        <div className="Add-vet-modal">
          <button
            className="Add-vet-modal-close"
            onClick={handleCancel}
            type="button"
          >
            <img alt="" src={close} />
          </button>

          <h2 className={hideZipcode ? 'Add-vet-modal-margin' : ''}>
            {t('MyVets.addVetModal.title')}
          </h2>

          {willChangeZipCode ? (
            <>
              <span className="Add-vet-modal-search">
                {t('MyVets.addVetModal.searchNear')}
              </span>

              <input
                className={`Add-vet-zip-code-input${zipCodeError
                  ? '-error' : ''}`}
                maxLength={5}
                onChange={handleChangeZipCode}
                onKeyDown={handleOnKeyDown}
                value={zipCode}
              />

              {zipCodeError ? (
                <span className="Add-vet-zip-code-error">{zipCodeError}</span>
              )
                : null}
            </>
          ) : (
            <>
              {!selectedVet && !hideZipcode && (
                <div>
                  <span className="Add-vet-modal-search">
                    {t('MyVets.addVetModal.searchingNear')}
                  </span>

                  <span className="Add-vet-modal-zipcode">
                    {zipCode}
                  </span>

                  |

                  <button
                    className="Add-vet-modal-change Like-span"
                    disabled={isLoadingVets}
                    onClick={switchToChangeZipCode}
                    type="button"
                  >
                    {t('MyVets.addVetModal.change')}
                  </button>
                </div>
              )}

              <SearchInput
                emptyMessage={renderNotListedMessage()}
                hideInput={isManuallyModalOpen || willChangeZipCode}
                id="addVet"
                isLoadingVets={isLoadingVets}
                itemListRender={renderFoundItem}
                itemSelected={selectedVet}
                itemsFound={vetsFound}
                onSearch={handleEmptyItemsFiltered}
                onSelected={selectItem}
                renderSelectedItem={renderSelectedItem}
              />
            </>
          )}

          {(willChangeZipCode || selectedVet) && (
            <>
              {addVetError ? (
                <span className="Add-vet-zip-code-error">
                  {t('MyVets.addVetModal.errorAdding')}
                </span>
              ) : null}

              <div className="Add-vet-modal-actions">
                <button
                  className="Add-vet-modal-cancel"
                  onClick={handleCancel}
                  type="button"
                >
                  {t('MyVets.addVetModal.cancel')}
                </button>

                <LoadingButton
                  className="Add-vet-modal-save"
                  loading={isSavingVet || isValidatingZipCode}
                  onClick={handleSave}
                  text={t('MyVets.addVetModal.save')}
                  type="button"
                />
              </div>
            </>
          )}
        </div>
      </ModalContainer>

      <AddVetManuallyModal
        closeModal={handleCloseAddVetManuallyModal}
        handleSuccesCustomVetModal={handleSuccesCustomVetModal}
        onSavePrimaryVet={onSelectedVet}
        primaryVet={primaryVet}
        show={isManuallyModalOpen}
        showCancelButton={customVetShowCancelButton}
      />
    </>
  );
};

export { AddVetModal };
