import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericModal } from '../common/petCloud/GenericModal';

import medicalRecords from '../../assets/claimbot/medical-records.svg';
import paidInvoice from '../../assets/claimbot/paid-invoice.svg';

import './FirstFigoClaimModal.css';

const FirstFigoClaimModal = ({
  onCancelPress = () => { },
  onContinuePress = () => { },
}) => {
  const { t } = useTranslation('fileClaimbot');

  return (
    <GenericModal
      description={t('firstFigoClaimModal.subtitle')}
      handleOutClick={onCancelPress}
      onPrimaryClick={onContinuePress}
      onSecondaryClick={onCancelPress}
      primaryButtonText={t('firstFigoClaimModal.primaryButton')}
      secondaryButtonText={t('firstFigoClaimModal.secondaryButton')}
      show
      title={t('firstFigoClaimModal.title')}
    >
      <div className="First-figo-claim-modal-item">
        <div className="First-figo-claim-modal-row">
          <img alt="" src={medicalRecords} />

          <span>{t('firstFigoClaimModal.medicalRecords.title')}</span>
        </div>

        <span>
          {t('firstFigoClaimModal.medicalRecords.content')}
        </span>
      </div>

      <div className="First-figo-claim-modal-item">
        <div className="First-figo-claim-modal-row">
          <img alt="" src={paidInvoice} />

          <span>{t('firstFigoClaimModal.paidInvoice.title')}</span>
        </div>

        <span>{t('firstFigoClaimModal.paidInvoice.content')}</span>
      </div>
    </GenericModal>
  );
};

export { FirstFigoClaimModal };
