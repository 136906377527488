import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { shallowEqual, useSelector } from 'react-redux';
import { PaymentMethodCard } from './PaymentMethodCard';
import { ViewMoreButton } from '../ViewMoreButton';
import { BillingInformation } from './BillingInformation';
import { BillingPremiumCostFigo } from './BillingPremiumCostFigo';
import { BillingPremiumCostOrca } from './BillingPremiumCostOrca';

import {
  PAYMENT_METHOD_NAMES,
  MULTIBRAND_POLICY_STATUS,
  PAYMENT_PLANS_BY_NAME,
  PAYMENT_PLANS,
} from '../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import {
  useBillingAndPayments,
} from '../../myAccount/BillingAndPayments/hooks/useBillingAndPayments';

import './BillingAndPayments.css';

export const BillingAndPayments = ({
  company = MARKETING_CHANNEL_ID.figo,
  isGPI = false,
  selectedPolicy = {},
}) => {
  const store = useSelector(({
    billingPayments,
    policies,
  }) => ({ billingPayments, policies }), shallowEqual);
  const { t } = useTranslation('insuranceScreen');

  const {
    MarketChannelId,
    NextBillingDate,
    PolicyDisplayNumber,
    StatusDisplay,
  } = selectedPolicy || {};

  const billingInformationFigo = useMemo(() => {
    if (!selectedPolicy) {
      return {
        billingName: '',
        paymentPlan: 0,
      };
    }

    const policyFound = store.policies.allPolicies
      .find((policy) => policy.Policy.Number === PolicyDisplayNumber) || {};

    let billingName = '';
    const { billingDetails } = store.billingPayments;

    if (billingDetails && (billingDetails.CCFirstName
      || billingDetails.CCLastName)) {
      billingName = `${billingDetails.CCFirstName} `
        + `${billingDetails.CCLastName}`;
    }

    return {
      ...policyFound.BillingInformation || {},
      billingName,
      paymentPlan: policyFound.Policy?.PaymentPlan,
    };
  }, [store.policies, PolicyDisplayNumber, store.billingPayments]);

  const billingInformationOrca = useMemo(() => {
    if (!selectedPolicy) {
      return {
        billingName: '',
        bottomInfo: [],
        paymentPlan: 0,
        topInfo: [],
        totalPayAmount: 0,
      };
    }

    const coverages = selectedPolicy.PetCoveragesByPet || {};
    // matches integer value from Figo enum
    const paymentPlan = selectedPolicy?.BillingCycle === PAYMENT_PLANS_BY_NAME
      .monthly ? PAYMENT_PLANS.monthly : PAYMENT_PLANS.annual;

    let billingName = '';
    const { PaymentOption } = selectedPolicy;

    if (PaymentOption && (PaymentOption.FirstNameOnCard
      || PaymentOption.LastNameOnCard)) {
      billingName = `${PaymentOption.FirstNameOnCard} `
        + `${PaymentOption.LastNameOnCard}`;
    }

    return {
      billingName,
      bottomInfo: [{
        Amount: coverages.PaymentBreakdown?.TotalPremiumCost,
        Name: t('billingInformation.premiumCosts'),
      },
      ...(selectedPolicy.CostLineItems || [])],
      paymentPlan,
      topInfo: coverages.CostLineItems || [],
      totalPayAmount: coverages.PaymentBreakdown?.TotalPayAmount,
    };
  }, [selectedPolicy, t]);

  const {
    ExpirationMonth,
    ExpirationYear,
    LastFour,
    PaymentMethodName,
  } = selectedPolicy?.PaymentOption || {};

  const hasPaymentMethod = PAYMENT_METHOD_NAMES.includes(PaymentMethodName);
  const isRedirectAvailable = useMemo(() => (
    StatusDisplay === MULTIBRAND_POLICY_STATUS.active
    || (StatusDisplay === MULTIBRAND_POLICY_STATUS.future
      && MarketChannelId === MARKETING_CHANNEL_ID.figo
    )), [StatusDisplay, MarketChannelId]);

  const {
    onOpenPaymentMethod,
  } = useBillingAndPayments({ policyInfo: selectedPolicy || {} });

  // :skull:
  const paymentMethodCardCases = {
    [PAYMENT_METHOD_NAMES[0]]: {
      lastNumbers: LastFour,
      methodType: 'savingBank',
    },
    [PAYMENT_METHOD_NAMES[1]]: {
      expirationDate: ExpirationMonth && ExpirationYear
        ? `${ExpirationMonth.toString().padStart(2, '0')}/${ExpirationYear}`
        : '',
      lastNumbers: LastFour,
      methodType: 'creditCard',
    },
    default: {
      isRedirectAvailable,
      methodType: 'addPaymentMethod',
      onAddPaymentMethod: onOpenPaymentMethod,
    },
  };

  const paymentMethodCardProps = paymentMethodCardCases[PaymentMethodName]
    || paymentMethodCardCases.default;

  if (selectedPolicy?.StatusDisplay === MULTIBRAND_POLICY_STATUS.cancelled
    || selectedPolicy?.StatusDisplay === MULTIBRAND_POLICY_STATUS.past) {
    return null;
  }

  return (
    <div className="Billing-payments__container">
      <div className="Billing-payments__header">
        <h3 className="Billing-payments__title">{t('billingAndPayments')}</h3>

        {hasPaymentMethod && isRedirectAvailable && (
          <ViewMoreButton
            onClick={onOpenPaymentMethod}
            text={t('viewBillingDetails')}
          />
        )}
      </div>

      {hasPaymentMethod && (
        <div className="Billing-payments__body">
          <div className="Billing-payments-general">
            {company === MARKETING_CHANNEL_ID.figo ? (
              <BillingPremiumCostFigo
                annualTotal={billingInformationFigo.PayAmount}
                isGPI={isGPI}
                monthlyInstallmentFee={billingInformationFigo.InstallmentFee}
                monthlyPremiumCost={billingInformationFigo.PayAmount}
                monthlyTotal={billingInformationFigo.TotalPayAmount}
                paymentPlan={billingInformationFigo.paymentPlan}
              />
            ) : (
              <BillingPremiumCostOrca
                bottomInfo={billingInformationOrca.bottomInfo}
                paymentPlan={billingInformationOrca.paymentPlan}
                topInfo={billingInformationOrca.topInfo}
                totalPayAmount={billingInformationOrca.totalPayAmount}
              />
            )}

            <BillingInformation
              name={company === MARKETING_CHANNEL_ID.figo
                ? billingInformationFigo.billingName
                : billingInformationOrca.billingName}
              nextBillingDate={NextBillingDate ? `${moment(NextBillingDate,
                'YYYY-MM-DDTHH:mm:ss.SSSZ')
                .format('MM/D/YYYY')}` : ''}
              paymentPlan={company === MARKETING_CHANNEL_ID.figo
                ? billingInformationFigo.paymentPlan
                : billingInformationOrca.paymentPlan}
            />
          </div>

          {company !== MARKETING_CHANNEL_ID.figo && (
            // TODO: Add this block when the Monthly Premiums data is ready
            <></>
          )}
        </div>
      )}

      <div className="Billing-payments__footer">
        <h5>{t('paymentMethod')}</h5>

        <PaymentMethodCard {...paymentMethodCardProps} />
      </div>
    </div>
  );
};
