import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import closeIcon from '../../assets/close-button.svg';
import { sleep } from '../../services/utils';
import { useResponsive } from '../../hooks/useResponsive';

import './ModalContainer.css';

export const MODAL_CONTAINER_ANIMATION_TYPE = {
  FROM_BOTTOM: 'FROM_BOTTOM',
  OPACITY: 'OPACITY',
};

const ModalContainer = ({
  animationType = null,
  cancelable = false,
  children,
  classCloseIcon,
  closeButtonStyle = '',
  contentStyle,
  customCloseIcon = null,
  disableClickOutside,
  handleOutClick = () => { },
  idPrefix = '',
  idTitle = '',
  isMultiModal,
  show,
}) => {
  const [bottom, setBottom] = useState(isMultiModal ? '-100vh' : '0');
  const animation = useMemo(() => {
    switch (animationType) {
      case MODAL_CONTAINER_ANIMATION_TYPE.FROM_BOTTOM:
        return {
          appear: 'modalAppear',
          disappear: 'modalDisappear',
        };

      case MODAL_CONTAINER_ANIMATION_TYPE.OPACITY:
        return {
          appear: 'modalAppearOpacity',
          disappear: 'modalDisappearOpacity',
        };

      default:
        return {
          appear: '',
          disappear: '',
        };
    }
  }, [animationType]);
  const [animationName, setAnimationName] = useState(animation.appear);
  const { isMobile } = useResponsive();

  const idOuter = `${idPrefix}modalOuter`;
  const dialogId = `${idPrefix}alert_dialog`;
  const closeId = `${idPrefix}closeModal`;
  const containerId = `${idPrefix}modalContainer`;

  useEffect(() => {
    if (isMobile) {
      if (show) {
        document.body.style.overflow = 'hidden';
        document.body.scroll = 'no';
      } else {
        document.body.style.overflow = 'scroll';
      }
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [isMobile, show]);

  const resetModalState = useCallback(() => {
    setAnimationName(animation.appear);
    handleOutClick();
  }, [handleOutClick]);

  async function handleCloseModal(event) {
    if (disableClickOutside && event.target.id === idOuter) {
      return;
    }

    setAnimationName(animation.disappear);

    await sleep(400);
    resetModalState();
  }

  function handleAnimationEnd() {
    if (animationName === animation.disappear) {
      resetModalState();
    } else {
      setBottom('0');
    }
  }

  function renderCloseButton() {
    if (cancelable) {
      return (
        <button
          aria-label="Close modal"
          className={`Modal-card-button-close ${closeButtonStyle}`}
          id={closeId}
          onClick={handleCloseModal}
          type="button"
        >
          <img
            alt="close modal"
            className={classCloseIcon
              || 'Modal-card-icon-close'}
            src={customCloseIcon || closeIcon}
          />
        </button>
      );
    }

    return null;
  }

  return (
    <>
      {show
        && (
          <div className="Modal-wrapper" id={containerId}>
            <FocusTrap active>
              <div className="Modal-container">
                <div
                  aria-hidden="true"
                  aria-labelledby={containerId}
                  className="Modal-backdrop"
                  id={idOuter}
                  onClick={handleCloseModal}
                  role="button"
                  tabIndex={-1}
                />

                <div
                  aria-labelledby={idTitle}
                  aria-modal="true"
                  className={'Modal-container-card-content '
                    + `${contentStyle || ''}`
                    + `${isMultiModal ? ' Multi-modal-card-container' : ''}`}
                  id={dialogId}
                  onAnimationEnd={handleAnimationEnd}
                  role="alertdialog"
                  style={{
                    animationName,
                    bottom,
                  }}
                >
                  {renderCloseButton()}

                  {children}
                </div>
              </div>
            </FocusTrap>
          </div>
        )}
    </>
  );
};

export default ModalContainer;
