import React from 'react';
import { useTranslation } from 'react-i18next';
import placeHolder from '../../../assets/myVets/placeholder-image.svg';
import removeVet from '../../../assets/myVets/my-pets-bin.svg';
import { EXPLORE_PROVIDERS } from '../../../constants';

import './MyVetCardItem.css';

const MyVetCardItem = ({
  handleRemoveVet = () => { },
  isVisitedVet = false,
  providerId = '',
  vetAddress1 = '',
  vetAddress2 = '',
  vetCity = '',
  vetEmail = '',
  vetFax = '',
  vetImage = '',
  vetName = '',
  vetState = '',
  vetTelephone = '',
  vetZipCode = '',
}) => {
  const { t } = useTranslation('myPets');

  return (
    <div className="My-vet-card-item-container">
      {isVisitedVet ? (
        <>
          <h4 className="My-vet-card-info-name">{vetName}</h4>

          <div className="My-vet-card-item-first-row">
            <span>
              {`${vetAddress1}, ${vetState}, ${vetCity}, ${vetZipCode}`}
            </span>

            {vetTelephone ? (
              <span>{`${t('MyVets.phone')}: ${vetTelephone}`}</span>
            ) : null}

            {vetFax ? (
              <span>{`${t('MyVets.fax')}: ${vetFax}`}</span>
            ) : null}
          </div>

          <span className="My-vet-card-item-email">{vetEmail}</span>
        </>
      ) : (
        <>
          <div className="My-vet-card-item-info-container">
            <div className="My-vet-card-image-container">
              <img
                alt="Placeholder"
                aria-label="Placeholder"
                className={vetImage ? 'My-vet-card-img' : 'My-vet-card-no-img'}
                src={vetImage || placeHolder}
              />
            </div>

            <h4 className="My-vet-card-info-name">{vetName}</h4>

            <p className="My-vet-card-info-telephone">{vetTelephone}</p>

            {providerId === EXPLORE_PROVIDERS.manuallyAdded ? (
              <p className="My-vet-card-info-address">
                {`${vetState}, ${vetCity}`}
              </p>
            ) : (
              <p className="My-vet-card-info-address">
                {vetAddress1}

                {vetAddress2 && `, ${vetAddress2}`}
              </p>
            )}
          </div>
        </>
      )}

      <button
        className="My-vet-card-remove-button btn"
        onClick={handleRemoveVet}
        type="button"
      >
        <img
          alt={t('myVets.removeVet')}
          aria-label={t('myVets.removeVet')}
          className="My-vet-card-remove-icon"
          src={removeVet}
        />
      </button>
    </div>
  );
};

export { MyVetCardItem };
