import React from 'react';
import petCloudPaw from '../../assets/sideMenu/petcloud-paw.svg';
import petCloudText from '../../assets/sideMenu/petcloud-text.svg';
import { ImpersonateBanner } from './ImpersonateBanner';

const Header = ({ centered = false }) => (
  <div className="Main-header-container">
    <div className="Main-header">
      <div className={`Main-header-logo ${
      centered ? 'Main-header-centered' : ''}`}
      >
        <img alt="" className="Main-header-paw" src={petCloudPaw} />

        <img alt="" src={petCloudText} />
      </div>
    </div>

    <ImpersonateBanner />
  </div>
);

export default Header;
