import { all, call, put } from 'redux-saga/effects';
import { apiCallWithApiKey, get, getData } from '../services/api';
import {
  breedMultibrandValidationFailed,
  breedMultibrandValidationSucceed,
  orcaGetPowerUpAttempt,
  orcaGetPowerUpFailed,
  orcaGetPowerUpLoaded,
} from '../actions';
import { WEBVIEW_API_TOKEN } from '../services/constants';
import {
  usePetCloudPolicyV1T172596ForSagas,
} from '../services/featureFlagsForSagas';

export function* getOrcaPowerUp(dispatch, { payload }) {
  try {
    yield put(orcaGetPowerUpAttempt());

    const { productKey } = payload;
    const getPublished = process.env.REACT_APP_NAME === 'Prod';
    const url =
      `api/Contentful/GetPowerDetails/${productKey}/${getPublished}`;

    const response = yield call(
      apiCallWithApiKey,
      url,
      dispatch,
    );

    if (response.success && response.IsValid) {
      yield put(orcaGetPowerUpLoaded(response.Data));
    } else {
      yield put(orcaGetPowerUpFailed());
    }
  } catch (e) {
    yield put(orcaGetPowerUpFailed());
  }
}

export function* validateBreedMultibrand(dispatch, { payload }) {
  try {
    const { petId } = payload;
    // Validate orca double active policies
    const url = 'api/Orca/Customer/HasMultiplePolicies';
    // get webview list information
    const webViewToken = getData(WEBVIEW_API_TOKEN);
    const query = { token: webViewToken };
    const isPolicyApi = yield usePetCloudPolicyV1T172596ForSagas();
    const api = isPolicyApi ? 'policy' : 'api';
    const webviewsData = `${api}/MultiBrand/GetQuoteUrls/${petId}`;

    const [response, respWebviewsData] = yield all([
      call(get, dispatch, url),
      call(get, dispatch, webviewsData, query),
    ]);

    if (response.success
      && response.IsValid
      && respWebviewsData.success
      && respWebviewsData.IsValid
    ) {
      dispatch(breedMultibrandValidationSucceed({
        hasMultipleOrcaPolicies: response.Data.HasMultipleOrcaPolicies,
        policyProvider: response.Data.PolicyProvider,
        webviewsData: respWebviewsData.Data,
      }));
    } else {
      dispatch(breedMultibrandValidationFailed());
    }
  } catch (e) {
    dispatch(breedMultibrandValidationFailed());
  }
}
