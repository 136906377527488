/* eslint-disable no-console */
/* eslint-disable max-len */
import { LogLevel } from '@azure/msal-browser';
import { getB2CRedirectUrl } from './services/utils';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  authorities: {
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_B2C_URL}/${process.env.REACT_APP_B2C_TENANT_URL}/B2C_1_Change_Password`,
      redirectUrl: `https://${process.env.REACT_APP_B2C_URL}/${process.env.REACT_APP_B2C_TENANT_URL}/oauth2/v2.0/authorize?p=B2C_1_Change_Password&client_id=${process.env.REACT_APP_B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fc61-test-3-petcloud-react.azurewebsites.net%2Fsettings%2FaccountAndLogin&scope=openid&response_type=id_token&prompt=login`,
    },
    impersonation: {
      authority: `https://${process.env.REACT_APP_B2C_URL}/${process.env.REACT_APP_B2C_TENANT_URL}/${process.env.REACT_APP_B2C_IMPERSONATION_NAME}`,
      redirectUrl: `${window.location.origin}/token_register`,
    },
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_B2C_URL}/${process.env.REACT_APP_B2C_TENANT_URL}/${process.env.REACT_APP_B2C_SIGNIN_NAME}`,
      redirectUrl: `${window.location.origin}/token_register`,
    },
  },
  authorityDomain: process.env.REACT_APP_B2C_URL,
  names: {
    forgotPassword: 'B2C_1_Change_Password',
    impersonation: process.env.REACT_APP_B2C_IMPERSONATION_NAME,
    signUpSignIn: process.env.REACT_APP_B2C_SIGNIN_NAME,
  },
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    clientId: process.env.REACT_APP_B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    postLogoutRedirectUri: `${getB2CRedirectUrl()}/`, // Indicates the page to navigate after logout.
    redirectUri: b2cPolicies.authorities.signUpSignIn.redirectUrl, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const documentsScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/customer/documents.write`;

export const claimBotScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/claim/claimbot.write`;

export const claimScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/claim/claim.write`;

export const customerScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/customer/customer.write`;

export const policyScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/policy/policy.write`;

export const webviewScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/orca/proxy/proxy`;

export const notificationScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/social/notification.write`;

export const contentfulScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/social/contentful.write`;

export const socialScope = `https://${process.env.REACT_APP_B2C_TENANT_URL}/pet-cloud/social/social.write`;

export const loginRequestScopes = {
  scopes: [customerScope, 'openid'],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Herev1.0/me', // e.g. https://graph.microsoft.com/v1.0/me
};
