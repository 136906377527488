import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../navigation/Header';
import { ContentfulComponent } from './petCloud/ContentfulComponent';
import { getContentfulPage } from '../../actions';
import Shimmer from './Shimmer';
import './ContentfulPage.css';

const ContentfulPage = ({ contentfulKey = '' }) => {
  const dispatch = useDispatch();
  const store = useSelector(({ common }) => common.contentfulPage);

  useEffect(() => {
    if (contentfulKey) {
      dispatch(getContentfulPage(contentfulKey));
    }
  }, [contentfulKey]);

  return (
    <>
      <Header centered />

      {store.loading ? <Shimmer className="Contentful-page-shimmer" />
        : (
          <div className="Contentful-page">
            <h1 className="Contentful-page-title">{store.title}</h1>

            <ContentfulComponent
              className="Contentful-page-content"
              htmlContent={store.content}
            />
          </div>
        )}
    </>
  );
};

export { ContentfulPage };
