import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isEmailValid,
  maskFaxNumber,
  maskPhoneNumber,
  removeEmojis,
  validateNumbersOnly,
  validatePhone,
  validateZipCode,
} from '../../../../services/utils';

const useCustomVetForm = ({
  closeModal = () => { },
  handleSuccesCustomVetModal = () => { },
  clearSuccessLoading = () => { },
}) => {
  const { t } = useTranslation('myPets');

  const types = {
    ADDRESS_ERROR: 'ADDRESS_ERROR',
    ADDRESS_VALUE: 'ADDRESS_VALUE',
    CITY_ERROR: 'CITY_ERROR',
    CITY_NAME: 'CITY_NAME',
    CLOSE_MODAL: 'CLOSE_MODAL',
    EMAIL_ERROR: 'EMAIL_ERROR',
    EMAIL_VALUE: 'EMAIL_VALUE',
    FAX_VALUE: 'FAX_VALUE',
    PHONE_ERROR: 'PHONE_ERROR',
    PHONE_VALUE: 'PHONE_VALUE',
    STATE_ERROR: 'STATE_ERROR',
    STATE_ID: 'STATE_ID',
    STATE_NAME: 'STATE_NAME',
    VET_COMPANY_NAME: 'VET_COMPANY_NAME',
    VET_COMPANY_NAME_ERROR: 'VET_COMPANY_NAME_ERROR',
    ZIP_CODE_ERROR: 'ZIP_CODE_ERROR',
    ZIP_CODE_VALUE: 'ZIP_CODE_VALUE',
  };

  const initialState = {
    cityError: '',
    cityName: '',
    stateId: '',
    stateName: '',
    vetAddressError: '',
    vetAddressValue: '',
    vetCompanyError: '',
    vetCompanyName: '',
    vetEmailError: '',
    vetEmailValue: '',
    vetFaxValue: '',
    vetPhoneError: '',
    vetPhoneValue: '',
    vetZipCodeError: '',
    vetZipCodeValue: '',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case types.ADDRESS_ERROR:
        return { ...state, vetAddressError: action.value };
      case types.ADDRESS_VALUE:
        return { ...state, vetAddressValue: action.value };
      case types.CITY_NAME:
        return { ...state, cityName: action.value };
      case types.CITY_ERROR:
        return { ...state, cityError: action.value };
      case types.CLOSE_MODAL:
        return initialState;
      case types.EMAIL_ERROR:
        return { ...state, vetEmailError: action.value };
      case types.EMAIL_VALUE:
        return { ...state, vetEmailValue: action.value };
      case types.FAX_VALUE:
        return { ...state, vetFaxValue: action.value };
      case types.PHONE_ERROR:
        return { ...state, vetPhoneError: action.value };
      case types.PHONE_VALUE:
        return { ...state, vetPhoneValue: action.value };
      case types.STATE_ID:
        return { ...state, stateId: action.value };
      case types.STATE_NAME:
        return { ...state, stateName: action.value };
      case types.STATE_ERROR:
        return { ...state, stateError: action.value };
      case types.VET_COMPANY_NAME_ERROR:
        return { ...state, vetCompanyError: action.value };
      case types.VET_COMPANY_NAME:
        return { ...state, vetCompanyName: action.value };
      case types.ZIP_CODE_ERROR:
        return { ...state, vetZipCodeError: action.value };
      case types.ZIP_CODE_VALUE:
        return { ...state, vetZipCodeValue: action.value };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function handleVetCompanyNameChange(event) {
    dispatch({
      type: types.VET_COMPANY_NAME,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.VET_COMPANY_NAME_ERROR, value: '' });
  }

  function handleCityNameChange(event) {
    dispatch({
      type: types.CITY_NAME,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.CITY_ERROR, value: '' });
  }

  function handleAddressChange(event) {
    dispatch({
      type: types.ADDRESS_VALUE,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.ADDRESS_ERROR, value: '' });
  }

  function handleZipCodeChange(event) {
    dispatch({
      type: types.ZIP_CODE_VALUE,
      value: validateZipCode(event.target.value),
    });
    dispatch({ type: types.ZIP_CODE_ERROR, value: '' });
  }

  function handleEmailChange(event) {
    dispatch({
      type: types.EMAIL_VALUE,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.EMAIL_ERROR, value: '' });
  }

  function handlePhoneChange(event) {
    dispatch({
      type: types.PHONE_VALUE,
      value: maskPhoneNumber(event.target.value),
    });
    dispatch({ type: types.PHONE_ERROR, value: '' });
  }

  function handleFaxChange(event) {
    const { value } = event.target;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');

    if (onlyNumbersValue) {
      dispatch({
        type: types.FAX_VALUE,
        value: maskFaxNumber(onlyNumbersValue),
      });
    }
  }

  function handleStateNameChange(event) {
    const { selectedIndex } = event.nativeEvent.target;
    const label = event.nativeEvent.target[selectedIndex].text;
    dispatch({ type: types.STATE_ID, value: event.target.value });
    dispatch({ type: types.STATE_NAME, value: label });
    dispatch({ type: types.STATE_ERROR, value: '' });
  }

  function handleVetCompanyError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.VET_COMPANY_NAME_ERROR,
      value: !state.vetCompanyName ? required : '',
    });
  }

  function handleAddressError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.ADDRESS_ERROR,
      value: !state.vetAddressValue ? required : '',
    });
  }

  function handleCityError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.CITY_ERROR,
      value: !state.cityName ? required : '',
    });
  }

  function handleZipCodeError() {
    let message = '';

    if (!state.vetZipCodeValue) {
      message = t('AddCustomVetModal.required');
    } else if (state.vetZipCodeValue.length < 5) {
      message = t('AddCustomVetModal.zipCodeError');
    }

    dispatch({
      type: types.ZIP_CODE_ERROR,
      value: message,
    });
  }

  function handleEmailError() {
    let message = '';
    const cleanValue = removeEmojis(state.vetEmailValue);

    if (!cleanValue) {
      message = '';
    } else if (!isEmailValid(cleanValue)) {
      message = t('AddCustomVetModal.invalidEmail');
    }

    dispatch({ type: types.EMAIL_ERROR, value: message });
  }

  function handlePhoneError() {
    let message = '';

    if (state.vetPhoneValue && !validatePhone(state.vetPhoneValue)) {
      message = t('AddCustomVetModal.invalidPhone');
    }

    dispatch({
      type: types.PHONE_ERROR,
      value: message,
    });
  }

  function handleStateError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.STATE_ERROR,
      value: !state.stateId ? required : '',
    });
  }

  function handleCloseModal() {
    dispatch({ type: types.CLOSE_MODAL });
    dispatch({ type: types.CITY_ERROR, value: '' });
    dispatch({ type: types.STATE_ERROR, value: '' });
    dispatch({ type: types.VET_COMPANY_NAME_ERROR, value: '' });
    dispatch({ type: types.EMAIL_ERROR, value: '' });
    dispatch({ type: types.PHONE_ERROR, value: '' });
    dispatch({ type: types.ZIP_CODE_ERROR, value: '' });
    closeModal();
  }

  function handleSuccess() {
    dispatch({ type: types.CLOSE_MODAL });
    clearSuccessLoading();
    handleSuccesCustomVetModal();
  }

  return {
    handleAddressChange,
    handleAddressError,
    handleCityError,
    handleCityNameChange,
    handleCloseModal,
    handleEmailChange,
    handleEmailError,
    handleFaxChange,
    handlePhoneChange,
    handlePhoneError,
    handleStateError,
    handleStateNameChange,
    handleSuccess,
    handleVetCompanyError,
    handleVetCompanyNameChange,
    handleZipCodeChange,
    handleZipCodeError,
    state,
  };
};

export { useCustomVetForm };
