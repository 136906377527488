import React from 'react';
import { useTranslation } from 'react-i18next';

import { EXPLORE_PROVIDERS } from '../../../constants';

import './AddAVetConfirm.css';

const AddAVetConfirm = ({
  handleEditButton = () => { },
  state,
  vetProvider = EXPLORE_PROVIDERS.google,
}) => {
  const { t } = useTranslation('myPets');

  function renderPhone() {
    if (!state.vetPhoneValue) {
      return null;
    }

    return (
      <span>
        {t('AddCustomVetModal.phoneConfirm', {
          phoneValue: state.vetPhoneValue,
        })}
      </span>
    );
  }

  function renderFax() {
    if (!state.vetFaxValue) {
      return null;
    }

    return (
      <span>
        {t('AddCustomVetModal.faxConfirm', {
          faxValue: state.vetFaxValue,
        })}
      </span>
    );
  }

  function renderEmail() {
    if (!state.vetEmailValue) {
      return null;
    }

    return (
      <span className="Add-a-vet-confirm-card-email">
        {state.vetEmailValue}
      </span>
    );
  }

  function renderAddress() {
    if (vetProvider !== EXPLORE_PROVIDERS.manuallyAdded) {
      return (
        <span className="Add-a-vet-confirm-address">
          {`${state.vetAddressValue1}${state.vetAddressValue2
            ? `,\n${state.vetAddressValue2}`
            : ''}`}
        </span>
      );
    }

    return (
      <span className="Add-a-vet-confirm-address">
        {`${state.vetAddressValue}, ${state.cityName},\n
        ${state.stateId} ${state.vetZipCodeValue}`}
      </span>
    );
  }

  function renderEditButton() {
    if (vetProvider !== EXPLORE_PROVIDERS.manuallyAdded) {
      return null;
    }

    return (
      <button
        className="Add-a-vet-confirm-edit-button"
        onClick={handleEditButton}
        type="button"
      >
        <span>{t('AddCustomVetModal.edit')}</span>
      </button>
    );
  }

  return (
    <div className="Add-a-vet-confirm-card">
      <div className="Add-a-vet-confirm-title-container">
        <h4>{state.vetCompanyName}</h4>

        {renderEditButton()}
      </div>

      {renderAddress()}

      <div className="Add-a-vet-confirm-row-container">
        {renderPhone()}

        {renderFax()}
      </div>

      {renderEmail()}
    </div>
  );
};

export { AddAVetConfirm };
