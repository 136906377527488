import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StatesList, AddVetInputContainer } from './AddVetManualComponents';

import './AddAVetForm.css';

const AddAVetForm = ({
  handleAddressChange = () => { },
  handleCityNameChange = () => { },
  handleEmailChange = () => { },
  handleFaxChange = () => { },
  handlePhoneChange = () => { },
  handleStateNameChange = () => { },
  handleVetCompanyNameChange = () => { },
  handleZipCodeChange = () => { },
  state,
}) => {
  const { t } = useTranslation('myPets');
  const store = useSelector(({ personalInformation }) => ({
    personalInformation,
  }), shallowEqual);
  const { states } = store.personalInformation;

  return (
    <>
      <AddVetInputContainer
        errorMessage={state.vetCompanyError}
        inputTitle={t('AddCustomVetModal.vetPractice')}
      >
        <input
          className="Add-a-vet-form-input-name"
          onChange={handleVetCompanyNameChange}
          placeholder={t('AddCustomVetModal.vetPractice')}
          value={state.vetCompanyName}
        />
      </AddVetInputContainer>

      <AddVetInputContainer
        errorMessage={state.vetAddressError}
        inputTitle={t('AddCustomVetModal.address')}
      >
        <input
          className="Add-a-vet-form-input-name"
          onChange={handleAddressChange}
          placeholder={t('AddCustomVetModal.address')}
          value={state.vetAddressValue}
        />
      </AddVetInputContainer>

      <div className="Add-a-vet-form-inputs-row-container">
        <AddVetInputContainer
          errorMessage={state.cityError}
          inputTitle={t('AddCustomVetModal.city')}
        >
          <input
            className="Add-a-vet-form-input-city-name"
            onChange={handleCityNameChange}
            placeholder={t('AddCustomVetModal.city')}
            value={state.cityName}
          />
        </AddVetInputContainer>

        <AddVetInputContainer
          errorMessage={state.stateError}
          inputTitle={t('AddCustomVetModal.state')}
        >
          <select
            className={!state.stateId
              ? 'Add-a-vet-form-select-state-placeholder'
              : 'Add-a-vet-form-select-state'}
            onChange={handleStateNameChange}
            value={state.stateId || ''}
          >
            <option disabled hidden value="">
              {t('AddCustomVetModal.statePlaceholder')}
            </option>

            <StatesList states={states} />
          </select>
        </AddVetInputContainer>

        <AddVetInputContainer
          errorMessage={state.vetZipCodeError}
          inputTitle={t('AddCustomVetModal.zipCode')}
        >
          <input
            className="Add-a-vet-form-input-zip-code"
            onChange={handleZipCodeChange}
            value={state.vetZipCodeValue}
          />
        </AddVetInputContainer>
      </div>

      <AddVetInputContainer
        errorMessage={state.vetEmailError}
        inputLegend={t('AddCustomVetModal.recommended')}
        inputTitle={t('AddCustomVetModal.email')}
      >
        <input
          className="Add-a-vet-form-input-email"
          onChange={handleEmailChange}
          value={state.vetEmailValue}
        />
      </AddVetInputContainer>

      <div className="Add-a-vet-form-inputs-row-container">
        <AddVetInputContainer
          errorMessage={state.vetPhoneError}
          inputLegend={t('AddCustomVetModal.optional')}
          inputTitle={t('AddCustomVetModal.phone')}
        >
          <input
            className="Add-a-vet-form-input-row"
            onChange={handlePhoneChange}
            value={state.vetPhoneValue}
          />
        </AddVetInputContainer>

        <AddVetInputContainer
          errorMessage={state.vetFaxError}
          inputLegend={t('AddCustomVetModal.optional')}
          inputTitle={t('AddCustomVetModal.fax')}
        >
          <input
            className="Add-a-vet-form-input-row"
            onChange={handleFaxChange}
            value={state.vetFaxValue}
          />
        </AddVetInputContainer>
      </div>
    </>
  );
};

export { AddAVetForm };
