import React from 'react';
import { useTranslation } from 'react-i18next';

import okIcon from '../../../assets/claims/ok.svg';
import LoadingButton from '../../common/LoadingButton';
import ModalContainer from '../../common/ModalContainer';
import './ConfirmPrimaryVetModal.css';

/**
 * @@deprecated
 *  Remove with PetCloudManualVetFlow
 */
const ConfirmPrimaryVetModal = ({
  error = false,
  loading = false,
  onBack = () => { },
  onClose = () => { },
  onConfirm = () => { },
  show = false,
  vetAddress = '',
  vetName = '',
}) => {
  const { t } = useTranslation('claims');

  return (
    <ModalContainer
      cancelable
      contentStyle="Confirm-primary-vet-modal-container"
      handleOutClick={onClose}
      show={show}
    >
      <img alt="" src={okIcon} />

      <h2>{t('savePrimaryVet.title')}</h2>

      <p>{t('savePrimaryVet.text')}</p>

      <span className="Confirm-primary-vet-modal-name">{vetName}</span>

      <span className="Confirm-primary-vet-modal-address">{vetAddress}</span>

      <span className="Confirm-primary-vet-modal-error">
        {error && t('savePrimaryVet.error')}
      </span>

      <div className="Flex">
        <button
          className="Confirm-primary-vet-modal-back"
          onClick={onBack}
          type="button"
        >
          {t('savePrimaryVet.back')}
        </button>

        <LoadingButton
          className="Confirm-primary-vet-modal-confirm"
          loading={loading}
          onClick={onConfirm}
          text={t('savePrimaryVet.confirm')}
          type="button"
        />
      </div>
    </ModalContainer>
  );
};

export { ConfirmPrimaryVetModal };
