import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  POWER_UPS_RELATION,
  WELLNESS_COVERAGE_LIMIT_FPI,
  WELLNESS_MULTIMODAL_ID,
} from '../../../changeCoverage/powerups/powerups.constans';
import { MULTIMODAL_SECTIONS } from '../../../changeCoverage/constants';
import {
  deductibleChanged,
  getChangeCoverageMultimodal,
  initialPowerUps,
  rateCoverage,
  reimbursementChanged,
  selectedPlanChanged,
} from '../../../../actions';

const { WELLNESS_PSM_ID } = POWER_UPS_RELATION;

const useCoverageModal = () => {
  const dispatch = useDispatch();
  const { policyNumber } = useParams();
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const [multiModalSectionSelected, setMultiModalSectionSelected] = useState(0);
  const [multiModalCategoryId, setMultiModalCategoryId] = useState(0);
  const [multiModalVisible, setMultiModalVisible] = useState(false);
  const { multimodalData, rate: {
    insuranceProduct,
    petQuoteResponseList = [],
    rateCreated,
  } } = store;

  const petInfo = useMemo(() => {
    if (petQuoteResponseList.length) {
      return petQuoteResponseList[0];
    }
    return {
      Plans: [],
    };
  }, [petQuoteResponseList]);

  useEffect(() => {
    dispatch(rateCoverage({ policyNumber }));
  }, [policyNumber]);

  useEffect(() => {
    if (rateCreated && petInfo.Plan) {
      const {
        Deductible,
        Deductibles,
        modifiers,
        Plan,
        Plans,
        Reimbursement,
        Reimbursements,
      } = petInfo;

      dispatch(selectedPlanChanged({
        planId: Plan,
        selectedAnnualBenefit:
          Plans.find((plan) => plan.Plan === Plan)?.MaxAnnual,
      }));
      const reimbursementDesc = Reimbursements
        .find((item) => item.Id === Reimbursement).Description || '';
      dispatch(reimbursementChanged({
        description: reimbursementDesc,
        reimbursementId: Reimbursement,
      }));
      const deductibleDesc = Deductibles
        .find((item) => item.Id === Deductible).Description || '';
      dispatch(deductibleChanged({
        deductibleId: Deductible,
        description: deductibleDesc,
      }));
      dispatch(initialPowerUps({ modifiers }));

      dispatch(getChangeCoverageMultimodal({ policyNumber }));
    }
  }, [rateCreated]);

  const multimodalDataSelected = useMemo(() => {
    let selection = null;
    if (multimodalData) {
      selection = multimodalData.InsuranceMultiModalSectionDtoList
        .find((data) => data.Id === multiModalSectionSelected);
    }

    if (!selection && multiModalSectionSelected && insuranceProduct) {
      const powerupInfo = insuranceProduct.InsuranceModifiers
        .find((modifier) => modifier.PMSModifierId
          === multiModalSectionSelected);

      return {
        InsuranceMultiModalCategoryDtoList: [{
          CategoryHeader: powerupInfo.TitleText,
          OptionalBenefitsDetailsItems: [
            ...powerupInfo.OptionalBenefitsDetailsItem,
          ],
          ...powerupInfo,
        }],
        Title: powerupInfo.TitleText,
      };
    }
    return selection;
  }, [insuranceProduct, multimodalData, multiModalSectionSelected]);

  const wellnessBenefits = useMemo(() => {
    if (insuranceProduct?.InsuranceModifiers) {
      const wellnessModifiers = insuranceProduct?.InsuranceModifiers
        .find((item) => item.PMSModifierId === WELLNESS_PSM_ID);

      return wellnessModifiers?.BundleInsuranceModifiers;
    }

    return [];
  }, [insuranceProduct]);

  function handleWellnessClick(coverageLimitId) {
    let newCategoryId = 0;
    if (coverageLimitId) {
      newCategoryId = WELLNESS_COVERAGE_LIMIT_FPI.BASIC
        .includes(coverageLimitId)
        ? WELLNESS_MULTIMODAL_ID.BASIC
        : WELLNESS_MULTIMODAL_ID.PLUS;
    }
    setMultiModalCategoryId(newCategoryId);
    setMultiModalVisible(true);
    setMultiModalSectionSelected(MULTIMODAL_SECTIONS.wellnessDetails);
  }

  function handleVetFeesClick(PMSModifierId) {
    setMultiModalVisible(true);
    setMultiModalSectionSelected(PMSModifierId);
  }

  function handlePetProtectCopayInfoClick() {
    setMultiModalVisible(true);
    setMultiModalSectionSelected(MULTIMODAL_SECTIONS.petProtectCopay);
  }

  return {
    handlePetProtectCopayInfoClick,
    handleVetFeesClick,
    handleWellnessClick,
    multiModalCategoryId,
    multimodalDataSelected,
    multiModalVisible,
    setMultiModalCategoryId,
    setMultiModalSectionSelected,
    setMultiModalVisible,
    wellnessBenefits,
  };
};

export { useCoverageModal };
