import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ContactUsCompany } from '../ContactUsCompany';
import { ContactUsHome } from '../ContactUsHome';

import akcLogo from '../../../../assets/brandProviderLogos/akc-logo.svg';
import ppiLogo from '../../../../assets/brandProviderLogos/ppi-logo.png';
import ppiOnePackLogo
from '../../../../assets/brandProviderLogos/ppi-one-pack-logo.svg';
import figoLogo from '../../../../assets/brandProviderLogos/figo-logo.svg';

import { CONTACT_US_STEPS } from '../contactUs.constants';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants';
import { getContactBrandData } from '../../../../actions';

const useContactUsModal = ({
  companyStep = '',
  hasHeader = false,
  show = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('sideMenu');
  const [currentStep, setCurrentStep] = useState(CONTACT_US_STEPS.HOME);

  const store = useSelector(({
    home,
    personalInformation,
  }) => ({ home, personalInformation }), shallowEqual);

  const insuranceCompanies = store.personalInformation.userInfo.Companies;

  useEffect(() => {
    dispatch(getContactBrandData());
  }, []);

  const contactInfo = useMemo(() => {
    if (!store.home.contactInfo) {
      return {
        akcInfo: {},
        figoInfo: {},
        ppiInfo: {},
        ppiOnePackInfo: {},
      };
    }

    const ppiOnePackInfo = store.home.contactInfo
      .find((item) => item.companyId === MARKETING_CHANNEL_ID.ppiOnePack);

    const ppiInfo = store.home.contactInfo
      .find((item) => item.companyId === MARKETING_CHANNEL_ID.ppi);

    const akcInfo = store.home.contactInfo
      .find((item) => item.companyId === MARKETING_CHANNEL_ID.akc);

    const figoInfo = store.home.contactInfo
      .find((item) => item.companyId === MARKETING_CHANNEL_ID.figo);

    return { akcInfo, figoInfo, ppiInfo, ppiOnePackInfo };
  }, [store.home.contactInfo]);

  const shouldRenderBackButton = useMemo(() => {
    if (hasHeader) {
      return false;
    }
    if (!hasHeader && currentStep === CONTACT_US_STEPS.HOME) {
      return false;
    }
    return true;
  }, [currentStep, companyStep]);

  useEffect(() => {
    if (companyStep) {
      setCurrentStep(companyStep);
    }
  }, [companyStep]);

  useEffect(() => {
    if (show && !hasHeader) {
      setCurrentStep(CONTACT_US_STEPS.HOME);
    }
  }, [show]);

  const onProviderClick = (step) => () => {
    setCurrentStep(step);
  };

  const onBackClick = () => {
    setCurrentStep(CONTACT_US_STEPS.HOME);
  };

  const renderContent = (extraText) => {
    switch (currentStep) {
      case CONTACT_US_STEPS.AKC:
        return (
          <ContactUsCompany
            contactInfo={contactInfo.akcInfo}
            extraText={extraText}
            logo={akcLogo}
            logoClass="Contact-us-akc-logo"
          />
        );

      case CONTACT_US_STEPS.FIGO:
        return (
          <ContactUsCompany
            contactInfo={contactInfo.figoInfo}
            extraText={extraText}
            logo={figoLogo}
            logoClass="Contact-us-figo-logo"
          />
        );

      case CONTACT_US_STEPS.PPI:
        return (
          <ContactUsCompany
            contactInfo={contactInfo.ppiInfo}
            extraText={extraText}
            logo={ppiLogo}
            logoClass="Contact-us-ppi-logo"
          />
        );

      case CONTACT_US_STEPS.PPI_ONE_PACK:
        return (
          <ContactUsCompany
            contactInfo={contactInfo.ppiOnePackInfo}
            extraText={extraText}
            logo={ppiOnePackLogo}
            logoClass="Contact-us-ppi-one-pack-logo"
          />
        );

      case CONTACT_US_STEPS.HOME:
      default:
        return (
          <ContactUsHome
            contactInfo={contactInfo}
            insuranceCompanies={insuranceCompanies}
            onProviderClick={onProviderClick}
          />
        );
    }
  };

  return {
    onBackClick,
    renderContent,
    shouldRenderBackButton,
    t,
  };
};

export { useContactUsModal };
