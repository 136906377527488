import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import LoadingActionButtonMyPets
from '../../common/petCloud/LoadingActionButtonMyPets';
import { ActionButton } from '../../common/petCloud/ActionButton';
import closeIcon from '../../../assets/close-button.svg';
import {
  addManuallyVetToFavorites,
  vetCustomSavedCleared,
} from '../../../actions/myVets';
import { useCustomVetForm } from './hooks/useCustomVetForm';

import { AddAVetConfirm } from './AddAVetConfirm';
import { AddAVetForm } from './AddAVetForm';

import { EXPLORE_PROVIDERS } from '../../../constants';

import './AddAVetModal.css';

const AddAVetModal = ({
  closeModal = () => { },
  handleSuccesCustomVetModal = () => { },
  show = false,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ myVets }) => ({ myVets }), shallowEqual);
  const { isSavingCustomVet, vetCustomSaved } = store.myVets;
  const { t } = useTranslation('myPets');
  const [isFilled, setIsFilled] = useState(false);

  function clearSuccessLoading() {
    dispatch(vetCustomSavedCleared());
  }

  const {
    handleAddressChange,
    handleAddressError,
    handleCityError,
    handleCityNameChange,
    handleCloseModal,
    handleEmailChange,
    handleEmailError,
    handleFaxChange,
    handlePhoneChange,
    handlePhoneError,
    handleStateError,
    handleStateNameChange,
    handleSuccess,
    handleVetCompanyError,
    handleVetCompanyNameChange,
    handleZipCodeChange,
    handleZipCodeError,
    state,
  } = useCustomVetForm({
    clearSuccessLoading,
    closeModal,
    handleSuccesCustomVetModal,
  });

  const invalidForm = useMemo(() => (!state.vetCompanyName
    || !state.cityName || !state.stateId
    || !state.vetZipCodeValue
    || !state.vetAddressValue
  ), [state]);

  function handleNextClick() {
    if (invalidForm) {
      handleVetCompanyError();
      handleCityError();
      handleStateError();
      handleAddressError();
      handleZipCodeError();
      handleEmailError();
      handlePhoneError();
      return;
    }

    setIsFilled(!isFilled);
  }

  function handleCancel() {
    setIsFilled(false);
    handleCloseModal();
  }

  function handleEditButton() {
    setIsFilled(false);
  }

  useEffect(() => {
    if (vetCustomSaved) {
      handleCloseModal();
    }
  }, [vetCustomSaved]);

  function handleSaveClick() {
    if (invalidForm) {
      handleVetCompanyError();
      handleCityError();
      handleStateError();
      handleAddressError();
      handleZipCodeError();
      handleEmailError();
      handlePhoneError();
      return;
    }

    dispatch(addManuallyVetToFavorites({
      address: state.vetAddressValue,
      city: state.cityName,
      email: state.vetEmailValue,
      fax: state.vetFaxValue,
      phone: state.vetPhoneValue,
      state: state.stateId,
      vetName: state.vetCompanyName,
      zipCode: state.vetZipCodeValue,
    }));
  }

  return (
    <ModalContainer show={show}>
      <div className="Add-a-vet-modal-container">
        <div className="Flex Flex-column">
          <div className="Add-a-vet-title-box">
            <h2 className="Add-a-vet-title">
              {t('MyVets.addAVet')}
            </h2>

            <button
              className="Add-a-vet-close-btn"
              id="closeIconBtn"
              onClick={handleCancel}
              type="button"
            >
              <img alt="" className="Add-figo-tag-close-icon" src={closeIcon} />
            </button>
          </div>

          <h3 className="Add-a-vet-modal-subtitle">
            {isFilled
              ? t('MyVets.checkInfo')
              : t('AddCustomVetModal.subtitle')}
          </h3>
        </div>

        {isFilled ? (
          <AddAVetConfirm
            handleEditButton={handleEditButton}
            state={state}
            vetProvider={EXPLORE_PROVIDERS.manuallyAdded}
          />
        ) : (
          <AddAVetForm
            handleAddressChange={handleAddressChange}
            handleCityNameChange={handleCityNameChange}
            handleEmailChange={handleEmailChange}
            handleFaxChange={handleFaxChange}
            handlePhoneChange={handlePhoneChange}
            handleStateNameChange={handleStateNameChange}
            handleVetCompanyNameChange={handleVetCompanyNameChange}
            handleZipCodeChange={handleZipCodeChange}
            state={state}
          />
        )}

        <div className="Add-a-vet-modal-buttons-container">
          <LoadingActionButtonMyPets
            loading={isSavingCustomVet}
            onClick={isFilled ? handleSaveClick : handleNextClick}
            onSuccessAnimationEnd={handleSuccess}
            success={vetCustomSaved}
            text={isFilled
              ? t('MyVets.addVetModal.add')
              : t('MyVets.addVetModal.next')}
            type="button"
          />

          <ActionButton
            className="Action-button Add-a-vet-cancel-button"
            isSecondaryButton
            onClick={handleCancel}
            title={t('AddCustomVetModal.cancel')}
            type="button"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export { AddAVetModal };
