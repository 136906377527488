import React from 'react';
import { useTranslation } from 'react-i18next';

import { useManualVet } from '../../../hooks';

import ModalContainer from '../../common/ModalContainer';

import placeHolderIcon from '../../../assets/myVets/veterinary-briefcase.svg';

import './NoVetModal.css';

const NoVetModal = ({
  show = false,
  onAddVetClick = () => { },
  onClose = () => { },
}) => {
  const { t } = useTranslation('claims');
  const isManualVetRedesign = useManualVet();

  const renderModalButtons = () => {
    if (isManualVetRedesign) {
      return (
        <div className="No-vet-modal-buttons-container">
          <button
            className="No-vet-modal-cancel-button"
            onClick={onClose}
            type="button"
          >
            {t('noPrimaryVet.cancel')}
          </button>

          <button
            className="No-vet-modal-save-button"
            onClick={onAddVetClick}
            type="button"
          >
            {t('noPrimaryVet.addAVet')}
          </button>
        </div>
      );
    }

    return (
      <button
        className="No-vet-modal-action-button Action-modal-button-blue"
        onClick={onAddVetClick}
        type="button"
      >
        {t('noPrimaryVet.action')}
      </button>
    );
  };

  return (
    <ModalContainer
      cancelable={!isManualVetRedesign}
      contentStyle={isManualVetRedesign
        ? 'No-vet-modal-content-container'
        : 'No-vet-modal-container'}
      handleOutClick={onClose}
      show={show}
    >
      {isManualVetRedesign ? (
        <img
          alt=""
          className="No-vet-modal-placeholder-img"
          src={placeHolderIcon}
        />
      ) : null}

      <h4>{t('noPrimaryVet.title')}</h4>

      <p>{t('noPrimaryVet.text')}</p>

      {renderModalButtons()}
    </ModalContainer>
  );
};

export { NoVetModal };
