import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePetPolicies } from '../../../hooks/usePetPolicies';
import Shimmer from '../../common/Shimmer';
import { PowerupInfoCard } from './PowerupInfoCard';

import './css/PowerupInfoCard.css';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import {
  POWER_UPS_RELATION,
} from '../../changeCoverage/powerups/powerups.constans';

const Powerups = ({
  company = MARKETING_CHANNEL_ID.figo,
  items = [],
  onClickLearnMore = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');
  const petPolicies = usePetPolicies();
  const {
    loading,
  } = petPolicies;

  function renderContent() {
    if (loading) {
      return (
        <Shimmer height={30} />
      );
    }

    return (
      <div className="Powerup-info-section-container">
        <h2>
          {company === MARKETING_CHANNEL_ID.figo
            ? t('powerUpsTitle')
            : t('upgradesTitle')}
        </h2>

        <div className="Powerup-info-grid-container">
          {items.map((benefits, index) => {
            let name = benefits.Name;
            let wellnessTypeId = null;

            if (benefits.Included && benefits
              .InsuranceModifierId === POWER_UPS_RELATION.WELLNESS_PSM_ID) {
              const includedWellness = benefits.OptionalBenefits?.find(({
                IsIncluded,
              }) => IsIncluded);

              name = includedWellness?.CategoryHeader;
              wellnessTypeId = includedWellness?.Id;
            }

            return (
              <PowerupInfoCard
                key={benefits.Id || String(index)}
                contentfulKey={benefits?.ContentfulKey}
                description={benefits.Description}
                included={benefits.Included}
                name={name}
                onClickLearnMore={onClickLearnMore}
                optionalBenefits={benefits.OptionalBenefits}
                wellnessTypeId={wellnessTypeId}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return renderContent();
};

export { Powerups };
