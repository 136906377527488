import React, { useMemo } from 'react';
import { MY_ACCOUNT } from '../../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants';

import { PolicyHeader } from '../../../insurance/PolicyHeader';
import BillingInfoScreen from '../BillingInfoScreen';

import '../css/BillingDetailsScreen.css';
import { Iframe } from '../../../common/iframe/Iframe';

const BillingDetailsScreen = ({ breadCrumbs }) => {
  const breadCrumbsInfo = useMemo(() => {
    if (breadCrumbs) {
      const breadCrumbsData = breadCrumbs.navData.find((data) => data.id
        === MY_ACCOUNT.billingAndPaymentDetails).extraData;

      return {
        marketingChannelId: breadCrumbsData?.marketingChannelId || 0,
        policyNumber: breadCrumbsData?.policyNumber || '',
        webViewData: {
          title: breadCrumbsData?.title || '',
          url: breadCrumbsData?.url || '',
        },
      };
    }

    return { marketingChannelId: 0, webViewData: { title: '', url: '' } };
  }, [breadCrumbs]);

  if (breadCrumbsInfo.marketingChannelId === MARKETING_CHANNEL_ID.figo) {
    return (
      <BillingInfoScreen policyNumber={breadCrumbsInfo.policyNumber} />
    );
  }

  return (
    <div className={'Billing-details-webview-header'
      + ` Colors-${breadCrumbsInfo.marketingChannelId}`}
    >
      <PolicyHeader company={breadCrumbsInfo.marketingChannelId} />

      <div className="Billing-details-webview-container">
        <Iframe
          className="Billing-details-webview"
          src={breadCrumbsInfo.webViewData.url}
          title={breadCrumbsInfo.webViewData.title}
        />
      </div>
    </div>
  );
};

export { BillingDetailsScreen };
