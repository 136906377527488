import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getThemeLogoById } from '../../theme/theme.utils';
import {
  CoveragePolicyStatus,
} from '../insurance/coverageOverview/CoveragePolicyStatus';
import { POLICY_STATUS } from '../../constants';

import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

import './PolicyListItem.css';

function formatDate(date) {
  return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
}

const PolicyListItem = ({
  endsOn,
  marketingChannel,
  onClick = () => { },
  policyNumber,
  startsOn,
  status,
  selected = false,
}) => {
  const { t } = useTranslation('insuranceScreen');
  const { dark } = getThemeLogoById(marketingChannel);
  const formattedStartDate = formatDate(startsOn);
  const formattedEndDate = formatDate(endsOn);

  return (
    <li className="Policy-list-item-container">
      <button data-selected={selected} onClick={onClick} type="button">
        <div className="Policy-list-item-data">
          <img
            alt=""
            className="Policy-list-item-logo"
            data-marketingchannel={marketingChannel}
            src={dark}
          />

          <div className="Policy-list-item-content">
            <div>
              <h3>{t('otherPolicies.policyNumber')}</h3>

              <p>{policyNumber}</p>
            </div>

            <div>
              <h3>
                {marketingChannel !== MARKETING_CHANNEL_ID.figo
                  ? t('coveragePeriod') : t('otherPolicies.policyTerm')}
              </h3>

              <p>{`${formattedStartDate} - ${formattedEndDate}`}</p>
            </div>
          </div>
        </div>

        <CoveragePolicyStatus
          label={status}
          status={POLICY_STATUS[status?.toLowerCase()]}
        />
      </button>
    </li>
  );
};

export { PolicyListItem };
