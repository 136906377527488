import React, { useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PetProfileScreenMyPets } from './petProfile/PetProfileScreenMyPets';
import { ImageEditorMyPets }
from '../common/imageEditorMyPets/ImageEditorMyPets';
import {
  addPetRestarted,
  changeProfilePicture,
  changeProfilePictureRestart,
  refreshPersonalInformation,
  removeCoverPicture,
  setSelectedPetId,
} from '../../actions';
import './NavigationMyPets.css';
import './petProfile/FamilyCoverPicture.css';
import {
  PET_TYPE_ID,
  ADD_PET_MODAL_STEPS,
  PRODUCT_FAMILY,
} from '../../constants';
import { MY_PETS } from '../navigation/routes.constants';
import { AddAPetModal } from './AddAPetModal';
import { DeleteAPetModal } from './DeleteAPetModal';
import { delay } from '../../services/utils';
import { CONTACT_US_STEPS }
from '../sideMenu/contactUs/contactUs.constants';
import { ContactUsModal } from '../sideMenu/contactUs/ContactUsModal';

const NavigationMyPets = ({ contactUsVisible, setContactUsVisible }) => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalAddPetVisible, setModalAddPetVisible] = useState(false);
  const [modalAddFamilyCoverVisible, setModalAddFamilyCoverVisible] =
    useState(false);
  const [modalDeletePetVisible, setModalDeletePetVisible] = useState(false);
  const [petInformation, setPetInformation] = useState(null);
  const [initialStep, setInitialStep] =
    useState(ADD_PET_MODAL_STEPS.FIRST_STEP);
  const { id } = useParams();
  const store = useSelector(
    ({
      myPets,
      personalInformation,
      policies,
    }) => ({
      myPets,
      personalInformation,
      policies,
    }), shallowEqual,
  );

  const {
    myPets: { selectedPetId, petProfile },
    policies: { allPolicies },
    personalInformation: { pictureEditing },
  } = store;

  const isGPI = useMemo(() => {
    if (allPolicies && selectedPetId) {
      const actualPet = allPolicies
        .find((pet) => pet.PetInformation.PetId === Number(selectedPetId));
      return actualPet?.Policy.ProductFamilyId === PRODUCT_FAMILY.GPI;
    }
    return false;
  }, [selectedPetId, allPolicies]);

  const onContactUsOpen = () => {
    setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(setSelectedPetId(id));
    }
  }, [id, dispatch]);

  function getPetInformation() {
    const { myPets: { breedData } } = store;
    const breedList = petProfile.Type === PET_TYPE_ID.DOG
      ? breedData.dogBreeds : breedData.catBreeds;

    let petBreed = breedList
      .find((breed) => breed.Name === petProfile.BreedName);

    if (!petBreed) {
      petBreed = {
        Id: petProfile.BreedId,
        IsDog: petProfile.Type === PET_TYPE_ID.DOG,
        Name: petProfile.BreedName,
      };
    }

    return {
      BreedInformation: petBreed,
      DateOfBirth: new Date(petProfile.DisplayDOB.split('.')[0]),
      isPetInsured: !!petProfile.PolicyNumber,
      PetType: petProfile.Type,
      ...petProfile,
    };
  }

  function handleOnEditPetClick({ isDescription = false }) {
    if (!Object.keys(petProfile).length) {
      return;
    }

    if (isDescription) {
      setInitialStep(ADD_PET_MODAL_STEPS.THIRD_STEP);
    }

    setPetInformation(getPetInformation());
    setModalAddPetVisible(true);
  }

  function handleOnAddFamilyCoverClick() {
    setModalAddFamilyCoverVisible(true);
  }

  function handleModalAddFamilyCoverClose() {
    setModalAddFamilyCoverVisible(false);
  }

  async function handleSaveFamilyCover(newImage) {
    const isCover = true;
    const imageBase64 = newImage.replace(/^data:(.*,)?/, '');

    dispatch(changeProfilePicture({ imageBase64, isCover }));
  }

  function handleSaveFamilyCoverSuccess() {
    setModalAddFamilyCoverVisible(false);
    dispatch(changeProfilePictureRestart());
    dispatch(refreshPersonalInformation());
  }

  function handleRemoveFamilyCover() {
    dispatch(removeCoverPicture());
  }

  function handleOnAddPetClose() {
    setInitialStep(ADD_PET_MODAL_STEPS.FIRST_STEP);
    setModalAddPetVisible(false);
    dispatch(addPetRestarted());
  }

  function openDeleteModal() {
    setModalDeletePetVisible(true);
    setModalAddPetVisible(false);
  }

  function closeDeleteModal() {
    setModalDeletePetVisible(false);

    if (allPolicies.length && allPolicies.length > 1) {
      const firstPetId = allPolicies[0].PetInformation.PetId;

      dispatch(setSelectedPetId(firstPetId));
      delay(1000).then(() => history.push(`${MY_PETS}/${firstPetId}`));
    }
  }

  function renderDeleteModal() {
    if (modalDeletePetVisible) {
      return (
        <DeleteAPetModal
          image={petInformation.ImageUrl}
          isInsured={petInformation.IsInsured}
          name={petInformation.Name}
          onClose={closeDeleteModal}
          petId={petInformation.Id}
          petTypeId={petInformation.PetType}
          show
        />
      );
    }

    return null;
  }

  function renderPetsContent() {
    return (
      <div>
        <PetProfileScreenMyPets
          handleOnAddFamilyCoverClick={handleOnAddFamilyCoverClick}
          handleOnEditPetClick={handleOnEditPetClick}
        />
      </div>
    );
  }

  return (
    <div className="My-pets-profile-content">
      <AddAPetModal
        initialStep={initialStep}
        isGPI={isGPI}
        modalAddPetVisible={modalAddPetVisible}
        onCloseModalAddPet={handleOnAddPetClose}
        onContactUsClick={onContactUsOpen}
        onDelete={openDeleteModal}
        petInformation={petInformation}
      />

      {renderDeleteModal()}

      {modalAddFamilyCoverVisible && (
        <ImageEditorMyPets
          classContainer="Family-cover-picture-container"
          classCropperContainer="Family-cover-picture-draggable-container"
          classInputContainer="Family-cover-picture-draggable-container"
          classRotateButtonsContainer="Family-cover-picture-rotate-buttons"
          classSliderContainer="Family-cover-picture-slider-container"
          error={pictureEditing.error ? t('errorPhoto') : ''}
          imageHeight={237}
          imageWidth={460}
          initialPicture={store.personalInformation.profile.CoverPicture}
          isSaving={pictureEditing.loading}
          onClose={handleModalAddFamilyCoverClose}
          onRemove={handleRemoveFamilyCover}
          onSave={handleSaveFamilyCover}
          onSuccess={handleSaveFamilyCoverSuccess}
          photoType={t('coverPicture')}
          round={false}
          saveSuccess={pictureEditing.success}
          show={modalAddFamilyCoverVisible}
          subtitle={t('addHeaderPhoto')}
          title={t('addFamilyPhoto')}
        />
      )}

      <ContactUsModal
        companyStep={CONTACT_US_STEPS.HOME}
        onClose={onContactUsClose}
        show={contactUsVisible}
      />

      {renderPetsContent()}
    </div>
  );
};

export { NavigationMyPets };
