import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useAlphabeticalSortedPetsWithPolicy,
  usePostMessage,
  useQuery,
} from '../../hooks';
import { getPrivateToken } from '../../actions';
import FigoLoadingOverlay from '../common/FigoLoadingOverlay';
import petPatient from '../../assets/updatePayment/pet-patient.png';
import { POLICY_STATUS } from '../../constants';
import BillingInfoScreen
from '../myAccount/BillingAndPayments/BillingInfoScreen';

import './UpdatePaymentMethodScreen.css';

const UpdatePaymentMethodScreen = () => {
  const query = useQuery();
  const publicToken = query.get('publicToken');
  const dispatch = useDispatch();
  const postMessage = usePostMessage();
  const policyNumberParam = query.get('policyNumber');
  const petIdMobile = query.get('petId');
  const { t } = useTranslation(['paymentMethod', 'settingsScreen']);
  const store = useSelector(({
    billingPayments,
    claims,
    policies,
    session,
    settings,
  }) => ({
    billingPayments,
    claims,
    policies,
    session,
    settings,
  }), shallowEqual);
  const { billingPayments: {
    updatingPaymentMethod,
  },
  settings: { featureFlags } } = store;
  const [localLoading, setLocalLoading] = useState(false);

  const loadingContent = useMemo(() => store.session.loading
    || store.policies.loading
    || updatingPaymentMethod, [
    store.session.loading,
    store.policies.loading,
    updatingPaymentMethod,
  ]);

  useEffect(() => {
    if (loadingContent || localLoading) {
      return;
    }

    postMessage('loading-finished');
    setLocalLoading(true);
  }, [loadingContent, localLoading]);

  useEffect(() => {
    if (!store.session.privateToken && publicToken && !featureFlags.loading) {
      dispatch(getPrivateToken({ publicToken }));
    }
  }, [
    dispatch,
    featureFlags.loading,
    publicToken,
    store.session.privateToken,
  ]);

  const getAlphabeticalSortedPets = useAlphabeticalSortedPetsWithPolicy()
    .filter((pet) => pet.policies.filter((petPolicy) => petPolicy.Status
      === POLICY_STATUS.active || petPolicy.Status === POLICY_STATUS.future)
      .length);

  const selectedPet = useMemo(() => {
    if (!getAlphabeticalSortedPets[0]) {
      return {};
    }

    let currentPet = getAlphabeticalSortedPets[0];

    if (petIdMobile) {
      const petFound = getAlphabeticalSortedPets
        .find((pet) => pet.PetId === Number(petIdMobile));

      if (petFound) {
        currentPet = petFound;
      }
    }

    return currentPet;
  }, [getAlphabeticalSortedPets, petIdMobile]);

  const selectedPolicy = useMemo(() => {
    if (!selectedPet || !selectedPet.policies) {
      return '';
    }

    if (policyNumberParam && publicToken) {
      const policyFound = selectedPet
        .policies.find((policy) => (policy.Status
          === POLICY_STATUS.active || POLICY_STATUS.future)
          && policy.Number === policyNumberParam);
      if (policyFound) {
        const { Number: policyNumber } = policyFound;
        return policyNumber;
      }
    }

    const { Number: policyNumber } = selectedPet
      .policies.find((policy) => policy.Status === POLICY_STATUS.active
        || POLICY_STATUS.future);

    return policyNumber;
  }, [selectedPet, policyNumberParam, publicToken]);

  const NoActivePolicies = () => (
    <div className="Update-payment-method-no-policies-container">
      <img alt="pet-patient" src={petPatient} />

      <span>{t('noActivePolicies')}</span>
    </div>
  );

  function renderContent() {
    if (selectedPolicy && !loadingContent) {
      // TODO: fix petId and policyId on query params with support old version
      // eslint-disable-next-line max-len
      // @link(https://petpartnersinc.visualstudio.com/Pet%20Cloud/_workitems/edit/123752)
      return (
        <BillingInfoScreen
          policyNumber={selectedPolicy}
        />
      );
    }

    return <FigoLoadingOverlay visible />;
  }

  return getAlphabeticalSortedPets.length || loadingContent
    ? renderContent() : <NoActivePolicies />;
};

export { UpdatePaymentMethodScreen };
