import React from 'react';
import { useTranslation } from 'react-i18next';

import { getData } from '../../services/api';
import { IMPERSONATE_KEY } from '../../services/constants';
import { boolParse } from '../../services/utils';

import alertIcon from '../../assets/impersonate-banner/alert-circle.svg';

import './ImpersonateBanner.css';

const ImpersonateBanner = () => {
  const { t } = useTranslation('common');
  const isImpersonateLocalStorage = getData(IMPERSONATE_KEY);
  const showBanner = boolParse(isImpersonateLocalStorage).isBool;

  if (showBanner) {
    return (
      <div className="Impersonate-banner-main-container">
        <img alt="" src={alertIcon} />

        <h1>{t('impersonateBanner.title')}</h1>
      </div>
    );
  }

  return null;
};

export { ImpersonateBanner };
