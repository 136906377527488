import React from 'react';
import { useTranslation } from 'react-i18next';
import placeHolderIcon from '../../../assets/myVets/veterinary-briefcase.svg';

import './MyVetsPlaceholder.css';

const MyVetsPlaceholder = ({ handleAddVetClick = () => { } }) => {
  const { t } = useTranslation('myPets');

  return (
    <div className="My-vets-placeholder-container">
      <img alt="" src={placeHolderIcon} />

      <h4>{t('MyVets.placeHolderTitle')}</h4>

      <span className="My-vets-placeholder-text">
        {t('MyVets.placeHolderText')}
      </span>

      <button
        className="My-vets-placeholder-button"
        onClick={handleAddVetClick}
        type="button"
      >
        <span>{t('MyVets.addAVet')}</span>
      </button>
    </div>
  );
};

export { MyVetsPlaceholder };
