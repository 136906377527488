import React from 'react';
import './AddVetManualComponents.css';

export const AddVetInputContainer = ({
  children,
  errorMessage = '',
  inputContainerCSS = '',
  inputLegend = '',
  inputTitle = '',
}) => (
  <div className={`My-pets-manual-add-input-container ${inputContainerCSS}`}>
    <span className="My-pets-manual-add-input-titles">
      {inputTitle}

      <span className="My-pets-manual-add-input-legend">
        {inputLegend}
      </span>
    </span>

    {children}

    {errorMessage && (
      <span className="My-pets-manual-add-error-message">
        {errorMessage}
      </span>
    )}
  </div>
);

export const StatesList = ({ states = [] }) => states?.map((usState) => (
  <option key={`${usState.Id}`} value={usState.Id}>
    {usState.StateName}
  </option>
));
