import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  INBOX_DOCS_FILE_CATEGORY,
  INBOX_DOCS_PRIVATE_FOLDERS,
  INBOX_DOCS,
} from '../../../../constants';
import { formatBytes } from '../../../../services/utils';

import chboxUnselected
from '../../../../assets/inboxDocsMultibrand/check-unchecked.svg';
import chboxSelected
from '../../../../assets/inboxDocsMultibrand/check-checked.svg';

import blackFolderIcon from '../../../../assets/inboxDocs/black-folder.svg';
import plusIcon from '../../../../assets/plus.svg';

import TouchableDiv from '../../../common/TouchableDiv';
import { PetItemCardImage } from '../../../myPets/PetItemCardImage';
import { InboxAndDocsMenu } from '../../InboxAndDocsMenu';
import {
  RecentFileIcon,
} from '../../recentActivity/RecentFileIcon';

import { useResponsive } from '../../../../hooks/useResponsive';
import { useMixpanel } from '../../../../hooks';
import { EV40 } from '../../../../services/mixpanel';
import { filePermissionsLoaded } from '../../../../actions';

import './InboxAndDocsItemRow.css';

const PRIVATE_FOLDERS = [...INBOX_DOCS_PRIVATE_FOLDERS, INBOX_DOCS.trash];
const rowId = 'inboxAndDocsItemRowId';

const InboxAndDocsItemRow = ({
  changeLocation = () => { },
  currentFolderId = 0,
  goToFolder = () => { },
  isSelected = false,
  itemRow = {},
  onAssignTagPetClick = () => { },
  onEditTagPetClick = () => { },
  onFileRowClick = () => { },
  onRowSelection = () => { },
}) => {
  const store = useSelector(({
    inboxAndDocs,
    policies,
  }) => ({ inboxAndDocs, policies }), shallowEqual);

  const { t } = useTranslation('inboxAndDocs');

  const isFolder = itemRow.Type === INBOX_DOCS_FILE_CATEGORY.folder;
  const isTrashFolder = String(currentFolderId) === INBOX_DOCS.trash;
  const isPrivateFolder = PRIVATE_FOLDERS.includes(currentFolderId);
  const { isTablet } = useResponsive();
  const { sendEvent } = useMixpanel();
  const dispatch = useDispatch();

  const docStyling = useMemo(() => {
    const docsFileSizeCSS = isTrashFolder && isTablet
      ? 'Docs-file-size-trash' : 'Docs-file-size';

    return docsFileSizeCSS;
  }, [isTrashFolder, isTablet]);

  const handleFileClick = (file) => () => {
    if (String(currentFolderId) !== INBOX_DOCS.trash) {
      sendEvent(EV40({ fileName: `${file.Name}${file.Metadata.Extension}` }));
      onFileRowClick(file);
    }
  };

  function handleRowSelection() {
    onRowSelection(itemRow);
  }

  function formatDate(date) {
    if (!date) {
      return '';
    }

    const cleanDate = date.replace('T', ' ').split('.')[0];
    const gtmDate = new Date(`${cleanDate}.000Z`);

    return moment(gtmDate).format('MMM DD, YYYY');
  }

  function formatFileSize(size) {
    if (!size) {
      return '-';
    }

    return (formatBytes(size));
  }

  function handleFolderClick() {
    if (!isTrashFolder) {
      const filePermissions = itemRow.FilePermissions;
      dispatch(filePermissionsLoaded(filePermissions));

      goToFolder({
        id: itemRow.Id,
        name: itemRow.Name,
      });
    }
  }

  function handleAssignPetTagClick() {
    onAssignTagPetClick({ fileId: itemRow.Id });
  }

  function handleEditPetTagClick() {
    onEditTagPetClick({
      fileId: itemRow.Id,
      previousPetIdTagged: itemRow.PetsId || [],
    });
  }

  const renderIconAndName = () => {
    if (isFolder) {
      return (
        <div className="Docs-folder-icon-row-container">
          <img
            alt=""
            className="Docs-folder-icon-row"
            src={blackFolderIcon}
          />

          <TouchableDiv
            className="Docs-folder-icon-label-row"
            id={`itemRow${itemRow.Id}`}
            onClick={handleFolderClick}
          >
            <span>{itemRow.Name}</span>
          </TouchableDiv>
        </div>
      );
    }

    const { Metadata, Name } = itemRow;
    const fileExtension = Metadata.Extension?.toLowerCase();
    const boldStyle = ' Bold';

    return (
      <div className="Docs-file-row-flex">
        <RecentFileIcon
          alt="file extension icon"
          className="Docs-file-icon-row"
          fileExtension={fileExtension}
        />

        <div className="Docs-file-description-col Docs-file-just-cont-cent">
          <TouchableDiv
            className="Docs-file-description-touchable-name"
            id={`fileRowElement${itemRow.Id}`}
            onClick={handleFileClick(itemRow)}
          >
            <span className={`No-clicked${!Metadata.IsRead ? boldStyle : ''}`}>
              {Name}
            </span>
          </TouchableDiv>
        </div>
      </div>
    );
  };

  const AssignPetsButton = () => (
    <TouchableDiv
      className="Assign-pet-button-container"
      id={`assignPetId${itemRow.Id}`}
      onClick={handleAssignPetTagClick}
    >
      <span>{t('assign')}</span>
    </TouchableDiv>
  );

  const PetImage = ({
    className,
    petImage,
    petName,
    petType,
  }) => {
    const styleContainer = 'Pet-item-card-image-container';

    if (petImage) {
      return (
        <img
          alt=""
          className={`${styleContainer} Docs-pet-assigned-image ${className
            || ''}`}
          src={petImage}
        />
      );
    }

    return (
      <PetItemCardImage
        containerClassName={styleContainer}
        hideAddPhoto
        imageClassName="Docs-pet-assigned-image"
        initialsClassName="Docs-pet-assigned-name-initials"
        petImage={petImage}
        petName={petName}
        petType={petType}
      />
    );
  };

  const renderAssignedPets = (pets) => {
    if (isFolder) {
      return null;
    }

    if (!pets || !pets.length) {
      return <AssignPetsButton />;
    }

    const MAX_PET_ASSIGNED_VIEW_COUNT = 5;
    const maxPetAssignedReached = pets.length > MAX_PET_ASSIGNED_VIEW_COUNT;

    let petImages = pets
      .map((petId) => store.policies.allPets
        .find((pet) => pet.PetId === petId))
      .filter((pet) => !!pet);

    if (maxPetAssignedReached) {
      petImages = petImages.slice(0, MAX_PET_ASSIGNED_VIEW_COUNT);

      petImages.push({
        ClassName: 'Docs-pet-assigned-plus',
        ImageUrl: plusIcon,
        PetId: 0,
      });
    }

    return petImages.map((pet) => (
      <PetImage
        key={String(pet.PetId)}
        className={pet.ClassName}
        petImage={pet.ImageUrl}
        petName={pet.Name}
        petType={pet.PetType}
      />
    ));
  };

  const docsAssignedStyle = useMemo(() => {
    const docsAssignedCSS = isPrivateFolder
      ? 'Docs-assigned-hidden' : 'Docs-assigned';

    return docsAssignedCSS;
  }, []);

  const rowStyle = useMemo(() => {
    const docsTableItemRowCSS = isSelected
      ? 'Docs-table-item-row-selected' : 'Docs-table-item-row';

    return docsTableItemRowCSS;
  }, [isSelected]);

  const checkboxes = useMemo(() => ({
    selected: chboxSelected,
    unselected: chboxUnselected,
  }), []);

  return (
    <tr className={rowStyle} id={rowId}>
      <td className="Docs-select-icon">
        <TouchableDiv
          className="Docs-select-icon-checkbox-container"
          id="checkboxIconRowSelection"
          onClick={handleRowSelection}
        >
          <img
            alt=""
            src={isSelected ? checkboxes.selected : checkboxes.unselected}
          />
        </TouchableDiv>
      </td>

      <td className="Docs-name">
        {renderIconAndName()}
      </td>

      <td className="Docs-date-added">
        {formatDate(itemRow.Metadata.LastAddedDate || itemRow.LastUpdate)}
      </td>

      <td className={docStyling}>
        {formatFileSize(itemRow.Metadata.Size)}
      </td>

      <td className={docsAssignedStyle}>
        {renderAssignedPets(itemRow.PetsId)}
      </td>

      {!isTrashFolder ? (
        <td className="Docs-dots-menu">
          <InboxAndDocsMenu
            displayingFolderContent
            filePermissions={store.inboxAndDocs.filePermissions}
            folderPermissions={itemRow.FolderPermissions}
            goToFolder={changeLocation}
            isFolder={isFolder}
            itemId={itemRow.Id}
            itemInfo={itemRow}
            onEditTagPetClick={handleEditPetTagClick}
            parentFolderId={itemRow.ParentFolderId}
          />
        </td>
      ) : null}
    </tr>
  );
};

export { InboxAndDocsItemRow };
