import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ScrollableDiv from '../../common/ScrollableDiv';
import Shimmer from '../../common/Shimmer';
import { MyVetCardItem } from './MyVetCardItem';
import { MyPetsAddVetModal } from '../addVetModal/MyPetsAddVetModal';
import { GenericModal } from '../../common/petCloud/GenericModal';
import { MyVetsPlaceholder } from './MyVetsPlaceholder';
import { PetCloudBreadcrumbs } from '../common/PetCloudBreadcrumbs';

import { getAllFavorites, removeVet } from '../../../actions/myVets';

import { useManualVet } from '../../../hooks';

import addVetIcon from '../../../assets/myVets/add.svg';

import { EXPLORE_PROVIDERS } from '../../../constants';

import './MyVetsComponent.css';

const MyVetsComponent = ({ petName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myPets');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const store = useSelector(({ myVets }) => ({
    favoriteVets: myVets.favoriteVets,
    isLoadingFavorites: myVets.isLoadingFavorites,
    isRemovingPet: myVets.isRemovingPet,
    removePetSuccess: myVets.removePetSuccess,
    vetCustomSaved: myVets.vetCustomSaved,
  }), shallowEqual);
  const [selectedVetToRemove, setSelectedVetToRemove] = useState(null);
  const [addVetModalOpen, setAddVetModalOpen] = useState(false);
  const [selectedVet, setSelectedVet] = useState(null);
  const isManualVetRedesign = useManualVet();

  useEffect(() => {
    dispatch(getAllFavorites());
  }, [dispatch]);

  useEffect(() => {
    if (store.removePetSuccess) {
      dispatch(getAllFavorites());
      setSelectedVetToRemove(null);
    }
  }, [dispatch, store.removePetSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (store.removePetSuccess) {
      setLoadingDelete(false);
    }
  }, [store.removePetSuccess]);

  const hasNoVets = useMemo(() => !store.isLoadingFavorites
    && !store.favoriteVets.length,
  [store, store.favoriteVets, store.isLoadingFavorites]);

  const showRemovePetCard = (vet) => () => {
    setSelectedVetToRemove(vet);
  };

  function hideRemovePetCard() {
    if (!store.isRemovingPet) {
      setSelectedVetToRemove(null);
    }
  }

  function handleAddVetClick() {
    setAddVetModalOpen(true);
  }

  function handleCloseAddVetModal() {
    setAddVetModalOpen(false);
  }

  function handleRemoveVet() {
    if (!store.isRemovingPet) {
      setLoadingDelete(true);
      dispatch(removeVet({
        isVisitedVet: selectedVetToRemove.isVisitedVet,
        placeId: selectedVetToRemove.vetPlaceId,
      }));
    }
  }

  useEffect(() => {
    if (store.vetCustomSaved) {
      handleCloseAddVetModal();
    }
  }, [store.vetCustomSaved]);

  const renderDeleteVetModal = () => (
    <GenericModal
      description={t('MyVets.removeVetDescription')}
      handleOutClick={hideRemovePetCard}
      isDeleteAction
      loadingPrimary={loadingDelete}
      onPrimaryClick={handleRemoveVet}
      onSecondaryClick={hideRemovePetCard}
      primaryButtonText={t('MyVets.deleteBtn')}
      secondaryButtonText={t('MyVets.keepVetBtn')}
      show={!!selectedVetToRemove}
      title={t('MyVets.deleteVet')}
    />
  );

  const renderVetScreenContent = () => {
    if (hasNoVets && isManualVetRedesign) {
      return <MyVetsPlaceholder handleAddVetClick={handleAddVetClick} />;
    }

    return (
      <>
        {store.favoriteVets.map((vet) => {
          let vetInfo = {};

          if (vet?.ProviderId) {
            const {
              Address1,
              Address2,
              City,
              Phone,
              PlaceId,
              PlaceName,
              ProviderId,
              State,
              Thumbnail,
            } = vet;

            vetInfo = {
              isVisitedVet: false,
              providerId: ProviderId,
              vetAddress1: Address1,
              vetAddress2: Address2,
              vetCity: City,
              vetEmail: '',
              vetFax: '',
              vetImage: Thumbnail,
              vetName: PlaceName,
              vetPlaceId: PlaceId,
              vetState: State,
              vetTelephone: Phone,
              vetZipCode: '',
            };
          } else {
            const {
              address,
              city,
              email,
              fax,
              id,
              phone,
              state,
              vetName,
              zipCode,
            } = vet;

            vetInfo = {
              isVisitedVet: true,
              providerId: EXPLORE_PROVIDERS.manuallyAdded,
              vetAddress1: address,
              vetAddress2: '',
              vetCity: city,
              vetEmail: email,
              vetFax: fax,
              vetImage: '',
              vetName,
              vetPlaceId: id,
              vetState: state,
              vetTelephone: phone,
              vetZipCode: zipCode,
            };
          }

          return selectedVetToRemove?.vetPlaceId === vetInfo.vetPlaceId
            ? renderDeleteVetModal() : (
              <MyVetCardItem
                key={String(vetInfo.vetPlaceId)}
                handleRemoveVet={showRemovePetCard(vetInfo)}
                isVisitedVet={vetInfo.isVisitedVet}
                providerId={vetInfo.providerId}
                vetAddress1={vetInfo.vetAddress1}
                vetAddress2={vetInfo.vetAddress2}
                vetCity={vetInfo.vetCity}
                vetEmail={vetInfo.vetEmail}
                vetFax={vetInfo.vetFax}
                vetImage={vetInfo.vetImage}
                vetName={vetInfo.vetName}
                vetState={vetInfo.vetState}
                vetTelephone={vetInfo.vetTelephone}
                vetZipCode={vetInfo.vetZipCode}
              />
            );
        })}
      </>
    );
  };

  return (
    <div className="My-pets-vets-container Flex Flex-column">
      <div className="breadcrumb-container">
        <PetCloudBreadcrumbs
          activeRoute={isManualVetRedesign
            ? t('MyVets.visitedVetsTitle')
            : t('MyVets.title')}
          rootRoute={petName}
        />
      </div>

      <div className="My-pets-vets-header Flex">
        <h2 className="My-pets-vets-title">
          {isManualVetRedesign
            ? t('MyVets.visitedVetsTitle')
            : t('MyVets.title')}
        </h2>

        {hasNoVets && isManualVetRedesign ? null : (
          <button
            className="My-pets-vets-add-vet-button"
            onClick={handleAddVetClick}
            type="button"
          >
            <span className="underline-wrapper">
              <img
                alt=""
                className="My-pets-vets-add-vet-button-icon"
                src={addVetIcon}
              />

              {t('MyVets.addaVet')}
            </span>
          </button>
        )}
      </div>

      <ScrollableDiv className="My-pets-vets-cards-container">
        <Shimmer
          className="Flex Flex-column"
          height={107}
          visible={store.isLoadingFavorites}
          width={384}
        >
          {renderVetScreenContent()}
        </Shimmer>
      </ScrollableDiv>

      {addVetModalOpen && (
        <MyPetsAddVetModal
          onClose={handleCloseAddVetModal}
          onSelectedVet={setSelectedVet}
          primaryVet={isManualVetRedesign}
          selectedVet={selectedVet}
          setAddVetModalOpen={setAddVetModalOpen}
        />
      )}
    </div>
  );
};

export { MyVetsComponent };
