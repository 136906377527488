import { handleActions } from 'redux-actions';
import { getDateForPostShare } from '../services/utils';

const INITIAL_STATE = {
  alert: {
    body: null,
    error: null,
    image: null,
    imageSize: null,
    inputErrorCallback: null,
    inputLabel: null,
    inputType: null,
    maxLength: null,
    overlayClose: false,
    placeholder: null,
    primaryAction: null,
    primaryText: null,
    secondaryAction: null,
    secondaryText: null,
    subText: null,
    subtitle: null,
    title: null,
    type: '',
    visible: false,
  },
  applicationMessages: null,
  comingSoon: {
    isSendingMessage: false,
    messageSent: false,
  },
  contentfulPage: {
    content: '',
    loading: false,
    title: '',
  },
  generalData: null,
  landing: {
    sideImage: '',
  },
  sharePost: {
    isLoading: false,
    postAuthorName: '',
    postAuthorPicPath: null,
    postContents: null,
    postDate: null,
    postId: null,
    postPhotoPath: null,
  },
  showGenericError: false,
  zipCodeDetails: {
    data: null,
    error: false,
    loadingZipCodeDetails: false,
  },
};

export default handleActions({
  APPLICATION_MESSAGES_LOADED: (state, { payload }) => ({
    ...state,
    applicationMessages: payload,
  }),
  CONTENTFUL_PAGE_LOADED: (state, { payload }) => ({
    ...state,
    contentfulPage: {
      content: payload.description?.replace(/&#160;/g, ' ') || '',
      loading: false,
      title: payload.title || '',
    },
  }),
  DISMISS_ALERT: (state) => ({
    ...state,
    alert: {
      ...state.alert,
      visible: false,
    },
  }),
  DO_LOGOUT: () => ({ ...INITIAL_STATE }),
  GENERAL_DATA_LOADED: (state, { payload }) => ({
    ...state,
    generalData: payload,
  }),
  GET_CONTENTFUL_PAGE: (state) => ({
    ...state,
    contentfulPage: {
      ...state.contentfulPage,
      loading: true,
    },
  }),
  GET_GENERAL_DATA: (state) => ({
    ...state,
    generalData: null,
  }),
  GET_SHARE_POST_DATA: (state) => ({
    ...state,
    sharePost: {
      ...state.sharePost,
      isLoading: true,
    },
  }),
  GET_SHARE_POST_DATA_FAILED: (state) => ({
    ...state,
    sharePost: {
      ...state.sharePost,
      isLoading: false,
    },
  }),
  GET_SHARE_POST_DATA_SUCCESS: (state, { payload }) => ({
    ...state,
    sharePost: {
      ...state.sharePost,
      isLoading: false,
      postAuthorName: payload.PostAuthorName,
      postAuthorPicPath: payload.PostAuthorPicturePath,
      postContents: payload.PostContents,
      postDate: getDateForPostShare(payload.PostDate),
      postId: payload.PostId,
      postPhotoPath: payload.PostPhotoPath,
    },
  }),
  LINK_MESSAGE_SENT: (state, { payload }) => ({
    ...state,
    comingSoon: {
      ...state.comingSoon,
      isSendingMessage: false,
      messageSent: payload,
    },
  }),
  LOAD_ZIP_CODE_DETAILS: (state) => ({
    ...state,
    zipCodeDetails: {
      ...state.zipCodeDetails,
      error: false,
      loadingZipCodeDetails: true,
    },
  }),
  LOAD_ZIP_CODE_DETAILS_FAILED: (state) => ({
    ...state,
    zipCodeDetails: {
      data: null,
      error: true,
      loadingZipCodeDetails: false,
    },
  }),
  LOAD_ZIP_CODE_DETAILS_RESTARTED: (state) => ({
    ...state,
    zipCodeDetails: {
      data: null,
      error: false,
      loadingZipCodeDetails: false,
    },
  }),
  PUBLIC_SIDE_IMAGE_LOADED: (state, { payload }) => ({
    ...state,
    landing: {
      ...state.landing,
      sideImage: payload,
    },
  }),
  SEND_LINK_MESSAGE: (state) => ({
    ...state,
    comingSoon: {
      ...state.comingSoon,
      isSendingMessage: true,
      messageSent: false,
    },
  }),
  SEND_MESSAGE_RESTARTED: (state) => ({
    ...state,
    comingSoon: {
      ...state.comingSoon,
      isSendingMessage: false,
      messageSent: false,
    },
  }),
  SHOW_ALERT: (
    state,
    {
      payload: {
        data: {
          body,
          error,
          image,
          imageSize = 64,
          inputErrorCallback,
          inputLabel,
          inputType,
          maxLength,
          overlayClose = false,
          placeholder,
          primaryAction,
          primaryText,
          secondaryAction,
          secondaryText,
          subText,
          subtitle,
          title,
          type = 'confirm',
        },
      },
    },
  ) => ({
    ...state,
    alert: {
      ...state.alert,
      body,
      error,
      image,
      imageSize,
      inputErrorCallback,
      inputLabel,
      inputType,
      maxLength,
      overlayClose,
      placeholder,
      primaryAction,
      primaryText,
      secondaryAction,
      secondaryText,
      subText,
      subtitle,
      title,
      type,
      visible: true,
    },
  }),
  SHOW_GENERIC_ERROR: (state, { payload }) => ({
    ...state,
    showGenericError: payload,
  }),
  ZIP_CODE_DETAILS_LOADED: (state, { payload }) => ({
    ...state,
    zipCodeDetails: {
      data: payload,
      error: false,
      loadingZipCodeDetails: false,
    },
  }),
}, INITIAL_STATE);
