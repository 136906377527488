import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { INSURANCE } from '../navigation/routes.constants';

import './OrcaGetQuoteFrame.css';
import { Iframe } from '../common/iframe/Iframe';
import { POST_MESSAGE_TYPES } from '../../constants';

const HTTPS_KEY = 'https://';

export const OrcaGetQuoteFrame = () => {
  const history = useHistory();
  const location = useLocation();
  const { state: { quoteUrl } } = location;
  const { t } = useTranslation('multibrand');

  const onMessage = React.useCallback(({ data, origin }) => {
    if (!quoteUrl) {
      return;
    }

    const [webViewOrigin] = quoteUrl.replace(HTTPS_KEY, '').split('/');
    const domain = `${HTTPS_KEY}${webViewOrigin}`;
    const validPostMessage = data === POST_MESSAGE_TYPES.CLOSE_PAGE
      || data === POST_MESSAGE_TYPES.QUOTE_CREATED;

    if (origin === domain && validPostMessage) {
      history.replace(INSURANCE);
    }
  }, [quoteUrl]);

  React.useLayoutEffect(() => {
    window.removeEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  useEffect(() => {
    if (!quoteUrl) {
      history.replace(INSURANCE);
    }
  }, [quoteUrl]);

  return (
    <div className="Orca-get-quote-frame">
      <Iframe
        allowFullScreen
        src={quoteUrl}
        title={t('orcaInsurance.addNewPet.addANewPet')}
      />
    </div>
  );
};
