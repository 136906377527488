export const B2C_TOKEN_REGISTER = '/token_register';
export const CHANGE_COVERAGE = '/changecoverage/:policyNumber';
export const CHANGE_COVERAGE_PREVIEW = '/insurance/changecoverage/preview';
export const CHANGE_COVERAGE_SUBMIT_SUCCESS = '/changecoverage/success';
export const CHANGE_COVERAGE_WEB = '/insurance/changecoverage';
export const ADD_ORCA_PET = '/insurance/addPet';
export const ADD_ORCA_PET_IFRAME = '/insurance/addPetQuote';
export const CLAIMS = '/claims';
export const DOWNLOAD_MOBILE_APPS = '/downloadApps';
export const ERROR = '/error';
export const FILE_CLAIMBOT = '/fileClaimbot';
export const FOUND_LOST_PET = '/foundLostPet';
export const HOME = '/home';
export const IMPERSONATE = '/impersonate';
export const INBOX_DOCS = '/inboxAndDocs';
export const INSURANCE = '/insurance';
export const IOS_URL = '/apple-app-site-association';
export const LOGIN = '/login';
export const MY_PETS = '/myPets';
export const OPTIONS = '/options';
export const PAYMENT_METHOD_EDIT = '/paymentMethod/edit';
export const REDIRECT = '/redirect';
export const SETTINGS = '/settings';
export const POST_SHARE = '/Post/Share';
export const MY_ACCOUNT = '/myAccount';
export const MICROCHIP = '/microchip';
export const MY_VETS = '/myVets';
export const PET_TAG = '/petTag';
export const TERMS_OF_USE = '/termsOfUse';
export const PRIVACY_POLICY = '/privacyPolicy';
