import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanMyVetsErrors, savePrimaryVet } from '../../../actions';
import { AddVetModal } from '../../myPets/addVetModal/AddVetModal';
import { ConfirmPrimaryVetModal } from './ConfirmPrimaryVetModal';
import { NoVetModal } from './NoVetModal';
import { useManualVet } from '../../../hooks';
import { MyPetsAddVetModal } from '../../myPets/addVetModal/MyPetsAddVetModal';

const AddPrimaryVet = ({ onClose = () => { } }) => {
  const [showNoVetModal, setShowNoVetModal] = useState(true);
  const [showAddVet, setShowAddVet] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedVet, setSelectedVet] = useState(null);
  const {
    isSavingVet,
    addVetError,
  } = useSelector(({ myVets }) => ({ ...myVets }));
  const isManualVetRedesign = useManualVet();

  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(cleanMyVetsErrors());
  }, []);

  useEffect(() => {
    if (selectedVet && !isManualVetRedesign) {
      setShowConfirmationModal(true);
      setShowAddVet(false);
    }
  }, [isManualVetRedesign, selectedVet]);

  function handleAddVetClick() {
    setShowAddVet(true);
    setShowNoVetModal(false);
  }

  function goBackToVetSelector() {
    setShowAddVet(true);
    setShowConfirmationModal(false);
    setSelectedVet(null);
    dispatch(cleanMyVetsErrors());
  }

  function closeModals() {
    onClose();
  }

  function addPrimaryVet() {
    dispatch(savePrimaryVet({
      isCustomVet: selectedVet.isCustom,
      selectedVetId: selectedVet.Id,
      stateId: selectedVet.StateId,
      vetCity: selectedVet.City,
      vetCompany: selectedVet.Name,
    }));
  }

  const renderAddVetModal = () => {
    if (isManualVetRedesign) {
      return showAddVet && (
        <MyPetsAddVetModal
          onClose={closeModals}
          onSelectedVet={setSelectedVet}
          primaryVet
          selectedVet={selectedVet}
          setAddVetModalOpen={setShowAddVet}
        />
      );
    }

    return (
      <>
        {showAddVet && (
          <AddVetModal
            customVetShowCancelButton
            displaySelectOnRows
            hideZipcode
            onClose={closeModals}
            onSelectedVet={setSelectedVet}
            primaryVet
            selectedVet={selectedVet}
          />
        )}

        {selectedVet && (
          <ConfirmPrimaryVetModal
            error={addVetError}
            loading={isSavingVet}
            onBack={goBackToVetSelector}
            onClose={closeModals}
            onConfirm={addPrimaryVet}
            show={showConfirmationModal}
            vetAddress={selectedVet.isCustom
              ? `${selectedVet.City}, ${selectedVet.StateName}`
              : selectedVet.Address}
            vetName={selectedVet.Name}
          />
        )}
      </>
    );
  };

  return (
    <>
      <NoVetModal
        onAddVetClick={handleAddVetClick}
        onClose={closeModals}
        show={showNoVetModal}
      />

      {renderAddVetModal()}
    </>
  );
};

export { AddPrimaryVet };
