import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import infoSelected from '../../../assets/info-blue-light.svg';
import info from '../../../assets/info-white.svg';

import { PowerUpTitle } from './PowerUpTitle';
import { currencyFormat } from '../../../services/utils';
import { WhatsCoveredLinkUpdate } from './WhatsCoveredLinkUpdate';
import { RadioButton } from '../../common/RadioButton';
import { useResponsive } from '../../../hooks/useResponsive';

import './css/UpdatedCardPowerup.css';

const UpdatedCardPowerup = ({
  bundleModifierAdd = () => { },
  bundleModifierCheckSelection = () => { },
  content,
  handleAddPowerup,
  handleRemovePowerup,
  hideInfoImage,
  onClickWhatsCovered,
  petQuoteSelected,
  powerUpInfo,
  price,
  selected,
  title,
}) => {
  const { t } = useTranslation('changeCoverage');
  const [isMounted, setMounted] = useState(false);
  const [localSelected, setLocalSelected] = useState(selected);
  const { isMobile } = useResponsive();

  useEffect(() => {
    setLocalSelected(selected);
  }, [petQuoteSelected, selected]);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  }, [isMounted]);

  const handleInfoPowerup = ({ powerUp }) => () => {
    if (powerUp.PMSModifierId) {
      onClickWhatsCovered();
    }
  };

  function renderInfoImage() {
    if (hideInfoImage) {
      return null;
    }

    return (
      <WhatsCoveredLinkUpdate
        className="Powerup-whats-covered "
        icon={localSelected ? infoSelected : info}
        id={title}
        onClick={handleInfoPowerup({ powerUp: powerUpInfo })}
        text={t('powerUpSection.whatsCovered')}
      />
    );
  }

  const handleAddPowerupOption = useCallback((event) => {
    if (!localSelected && handleAddPowerup) {
      handleAddPowerup(event);
    }
  }, [handleAddPowerup, localSelected]);

  const handleRemovePowerupOption = useCallback((event) => {
    if (localSelected && handleRemovePowerup) {
      handleRemovePowerup(event);
    }
  }, [handleRemovePowerup, localSelected]);

  const powerupCardClassname = useMemo(
    () => `Powerup-card-container${localSelected ? '-selected' : ''}`,
    [localSelected],
  );

  function renderOptions() {
    const contentModifiers = powerUpInfo.BundleInsuranceModifiers
      ?.filter((modifier) => modifier.IsVisible && modifier.IsActive);

    if (contentModifiers && contentModifiers.length) {
      const bundleModifiers = [...contentModifiers].reverse();

      return bundleModifiers.map((item, index) => (
        <RadioButton
          key={item.Id}
          checked={bundleModifierCheckSelection(item.Id)}
          name={`${index}-${title.replace(/\s/g, '')}`}
          onChange={bundleModifiers.length >= 2
            ? bundleModifierAdd(
              {
                isSelected: !bundleModifierCheckSelection(item.Id),
                powerUp: item,
              },
            )
            : handleAddPowerupOption}
        >
          <div className="Powerup-card-option-wrapper">
            <span className="Powerup-card-option">
              {t('options.yesPowerup', {
                powerup: `${powerUpInfo.TitleText} ${item.TitleText}`,
              })}

              <span>
                {currencyFormat({ value: item.FeeAmount })}

                {isMobile
                  ? t('options.monthly')
                  : t('options.mMonthlyPremium')}
              </span>
            </span>
          </div>
        </RadioButton>
      ));
    }

    return (
      <RadioButton
        checked={localSelected}
        name={`yes-${title.replace(/\s/g, '')}`}
        onChange={handleAddPowerupOption}
      >
        <span className="Powerup-card-option">
          {t('options.yesPowerup', { powerup: powerUpInfo.TitleText })}

          <span>
            {currencyFormat({ value: price })}

            {isMobile
              ? t('options.monthly')
              : t('options.mMonthlyPremium')}
          </span>
        </span>
      </RadioButton>
    );
  }

  return (
    <section className={powerupCardClassname}>
      <div className="Powerup-card-body-content">
        <PowerUpTitle localSelected={localSelected} title={title}>
          {renderInfoImage()}
        </PowerUpTitle>

        <div className="Powerup-card-content">
          {content}

          <div
            className={'Powerup-card-actions-radios'
              + `${hideInfoImage ? ' Powerup-card-actions-end' : ''}`}
          >
            {renderOptions()}

            <RadioButton
              checked={!localSelected}
              name={`no-${title.replace(/\s/g, '')}`}
              onChange={handleRemovePowerupOption}
            >
              {t('options.noPowerup')}
            </RadioButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdatedCardPowerup;
