import './ChangeCoverageFrame.css';
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PetCloudBreadcrumbs,
} from '../../../myPets/common/PetCloudBreadcrumbs';
import { POST_MESSAGE_TYPES, WEB_VIEW_TYPES } from '../../../../constants';
import { useWebviewMultibrand } from '../../../../hooks';
import { Iframe } from '../../../common/iframe/Iframe';

const HTTPS_KEY = 'https://';

export const ChangeCoverageFrame = ({
  onClose = () => { },
  petId = '',
  policy = {},
}) => {
  const { t } = useTranslation('insuranceScreen');
  const {
    getWebView,
    isLoading,
    title,
    url,
  } = useWebviewMultibrand();

  const { Id, MarketChannelId, PolicyDisplayNumber } = policy;

  const onMessage = React.useCallback(({ data, origin }) => {
    if (!url) {
      return;
    }

    const [webViewOrigin] = url.replace(HTTPS_KEY, '').split('/');
    const domain = `${HTTPS_KEY}${webViewOrigin}`;
    const validPostMessage = data === POST_MESSAGE_TYPES.CLOSE_PAGE
      || data === POST_MESSAGE_TYPES.POLICY_UPDATED;

    if (origin === domain && validPostMessage
      && typeof onClose === 'function') {
      onClose();
    }
  }, [url]);

  useEffect(() => {
    const params = {
      companyId: MarketChannelId,
      petId,
      policyId: Id,
      policyNumber: PolicyDisplayNumber,
      webViewType: WEB_VIEW_TYPES.changeCoverage,
    };

    if (getWebView) {
      getWebView(params);
    }
  }, [getWebView, petId, policy]);

  useLayoutEffect(() => {
    window.removeEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return (
    <div
      className={`Change-coverage-frame__container Colors-${MarketChannelId}`}
    >
      <PetCloudBreadcrumbs
        activeRoute={t('changeCoverage')}
        rootRoute={t('title')}
      />

      <div className="Change-coverage-frame__page-title">
        <h1>{t('changeCoverage')}</h1>
      </div>

      <div className="Coverage-overview-iframe">
        {!isLoading && (
          <Iframe allowFullScreen src={url} title={title} />
        )}
      </div>
    </div>
  );
};
