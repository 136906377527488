import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { doNothing } from '../../services/utils';
import { GenericModal } from './petCloud';

const URGENT_MESSAGES_KEY = 'urgent-messages';

export const UrgentMessages = () => {
  const { t } = useTranslation('common');
  const store = useSelector(({ common }) => common);
  const [show, setShow] = useState(false);
  const { applicationMessages } = store;

  const onClose = () => {
    setShow(false);
  };

  React.useEffect(() => {
    if (!applicationMessages?.Id) {
      return;
    }

    try {
      const { Id } = applicationMessages;

      const lastMessagesRaw = sessionStorage.getItem(URGENT_MESSAGES_KEY);
      const lastMessages = lastMessagesRaw ? JSON.parse(lastMessagesRaw) : [];
      const isNew = !lastMessages.includes(Id);

      if (isNew) {
        setShow(true);
        sessionStorage
          .setItem(URGENT_MESSAGES_KEY, JSON.stringify([...lastMessages, Id]));
      }
    } catch (_) {
      doNothing();
    }
  }, [applicationMessages]);

  return (
    <GenericModal
      handleOutClick={onClose}
      onPrimaryClick={onClose}
      primaryButtonText={t('okButton')}
      show={show}
      title={applicationMessages?.Title}
    >
      <p>{applicationMessages?.MessageBody}</p>
    </GenericModal>
  );
};
