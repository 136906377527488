export const USER_COMPANY_KEY = 'user_company';
export const USER_INFO_KEY = 'user_info';
export const IMPERSONATE_KEY = 'impersonate';
export const IMPERSONATE_EMAIL = 'impersonate_email';
export const BANNER_PREFERENCES_KEY = 'banner_preferences';

export const CUSTOMER_API_TOKEN = 'customer_api_token';
export const CLAIMS_API_TOKEN = 'claims_api_token';
export const POLICY_API_TOKEN = 'policy_api_token';
export const API_TOKEN = 'token';
export const WEBVIEW_API_TOKEN = 'webview_api_token';
export const SOCIAL_API_TOKEN = 'social_api_token';

export const contentfulPages = {
  PRIVACY_POLICY: 'pc-privacypolicy',
  TOS: 'pc-termsofservice',
};
