import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useOnEscEvent } from '../../../hooks';
import { useResponsive } from '../../../hooks/useResponsive';

import closeIcon from '../../../assets/close-button.svg';
import loadingSpinner from '../../../assets/lottieFiles/loading-blue.json';

import ModalContainer from '../../common/ModalContainer';
import ScrollableDiv from '../../common/ScrollableDiv';
import { ModifierModalInfoItemContent } from './ModfierModalInfoItemContent';
import { ModifierWellnessGroup } from './ModifierWellnessGroup';
import { ContentfulComponent } from '../../common/petCloud/ContentfulComponent';
import FigoLottie from '../../common/FigoLottie';

import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { WELLNESS_MODIFIER_ID } from '../../../constants';

import { currencyFormat } from '../../../services/utils';

import {
  WELLNESS_COVERAGE_LIMIT_FPI,
  WELLNESS_MULTIMODAL_ID,
} from '../../changeCoverage/powerups/powerups.constans';

import './css/ModifierModalInfo.css';

const ModifierModalInfo = ({
  company = MARKETING_CHANNEL_ID.figo,
  initialMenuSelected = 0,
  isVisible,
  isWhatsCovered = false,
  multiModalData,
  onClose,
  policyNumber,
  wellnessBenefits = null,
}) => {
  const { t } = useTranslation('changeCoverage');
  const store = useSelector(({
    orcaInsurance,
  }) => ({ orcaInsurance }), shallowEqual);
  const {
    orcaInsurance: {
      powerUpDetailsLoading,
      powerUpDetails,
    },
  } = store;
  const [menuSelected, setMenuSelected] = useState(initialMenuSelected);
  const titleContainerStyle = isWhatsCovered
    ? 'Modifier-whats-covered-title-container'
    : 'Modifier-modal-info-content-title-container';
  const { isTablet } = useResponsive();

  useEffect(() => {
    setMenuSelected(initialMenuSelected);
  }, [initialMenuSelected]);

  useOnEscEvent(() => {
    if (isVisible) {
      onClose();
    }
  }, [isVisible, onClose]);

  function filterByPolicyNumber() {
    if (multiModalData && multiModalData.PolicyOptionalDetailsDto) {
      return (
        multiModalData.PolicyOptionalDetailsDto
          .find((element) => element.PolicyNumber === policyNumber) || {}
      );
    }

    return {};
  }

  function getModalContent() {
    if (multiModalData && multiModalData.InsuranceMultiModalCategoryDtoList) {
      return multiModalData;
    }

    return {};
  }

  const multiModalContent = policyNumber
    ? filterByPolicyNumber()
    : getModalContent();

  function handleCloseModal() {
    if (onClose) {
      onClose();
    }
  }

  const categoryList = useMemo(() => {
    if (multiModalContent.OptionalsBenefit) {
      return multiModalContent.OptionalsBenefit;
    }

    const categoryDto = multiModalContent.InsuranceMultiModalCategoryDtoList;

    if (categoryDto) {
      const isWellnessMenu = categoryDto
        .find((item) => item.Id === WELLNESS_MULTIMODAL_ID.BASIC
        || item.Id === WELLNESS_MULTIMODAL_ID.PLUS);

      if (wellnessBenefits.length === 1 && isWellnessMenu) {
        const coverageLimitId = wellnessBenefits[0]?.CoverageLimitId;
        const categoryId = WELLNESS_COVERAGE_LIMIT_FPI.BASIC
          .includes(coverageLimitId)
          ? WELLNESS_MULTIMODAL_ID.BASIC
          : WELLNESS_MULTIMODAL_ID.PLUS;

        return categoryDto.filter((item) => item.Id === categoryId);
      }

      return categoryDto;
    }

    return [];
  }, [multiModalContent]);

  const currentCategory = useMemo(() => {
    if (categoryList && categoryList.length) {
      return categoryList.find((element) => element.Id === menuSelected);
    }

    return {
      CategoryHeader: '',
      ImageUrl: '',
      InsuranceModifierId: '',
      InsuranceMultiModalItemsDtoList: [],
      IsIncluded: '',
      OptionalBenefitsDetailsItems: [],
      SubTitle: '',
    };
  }, [categoryList, menuSelected]);

  useEffect(() => {
    if (categoryList[0] && categoryList[0].Id) {
      setMenuSelected(categoryList[0].Id);
    }
  }, [categoryList]);

  function getHeaderTitle() {
    if (company !== MARKETING_CHANNEL_ID.figo) {
      return powerUpDetailsLoading ? {
        subTitle: '',
        title: '',
      } : {
        subTitle: powerUpDetails?.Subtitle,
        title: powerUpDetails?.Title,
      };
    }

    return {
      included: currentCategory?.IsIncluded
        ? t('included') : t('notIncluded'),
      isIncluded: currentCategory?.IsIncluded,
      subTitle: currentCategory?.SubTitle,
      title: currentCategory?.CategoryHeader,
    };
  }

  function getHeaderImage() {
    return currentCategory?.ImageUrl;
  }

  function getCategoryContent() {
    return currentCategory?.OptionalBenefitsDetailsItems
      || currentCategory?.InsuranceMultiModalItemsDtoList
      || [];
  }

  const headerTitle = getHeaderTitle();
  const headerImage = getHeaderImage();
  const categoryContent = getCategoryContent();

  function renderModalContent() {
    if (company === MARKETING_CHANNEL_ID.figo) {
      return categoryContent.map((element) => (
        <ModifierModalInfoItemContent
          key={String(element.Id)}
          isBold={element.IsBold}
          subtitle={element.ItemSubtext || element.ItemSubText || ''}
          title={element.ItemLabel}
          value={policyNumber && element.Value
            ? currencyFormat({ value: element.Value })
            : element.Value}
        />
      ));
    }

    if (powerUpDetailsLoading) {
      return (
        <div className="Reimbursement-screen-shimmer-container">
          <FigoLottie
            animationData={loadingSpinner}
            height={50}
            width={50}
          />
        </div>
      );
    }

    return (
      <ContentfulComponent
        className="Modifier-modal-contentful-content"
        htmlContent={powerUpDetails?.DescriptionHTML}
      />
    );
  }

  function renderWellnessContent() {
    if (company === MARKETING_CHANNEL_ID.figo
      && (currentCategory?.InsuranceModifierId === WELLNESS_MODIFIER_ID
        || headerTitle?.title?.includes('Wellness'))
      && wellnessBenefits.length > 1) {
      return (
        <ModifierWellnessGroup
          categoryList={categoryList}
          isWhatsCovered={isWhatsCovered}
          menuSelected={menuSelected}
          onClickMenu={setMenuSelected}
        />
      );
    }

    return null;
  }

  return (
    <ModalContainer
      cancelable={false}
      disableClickOutside={isTablet}
      handleOutClick={handleCloseModal}
      id="modalContainerNeedToKnow"
      show={isVisible}
    >
      <ScrollableDiv
        className="Modifier-modal-info-container"
        scrollbarMargin
      >
        <div className="Modifier-modal-info-content-container">
          <div className={titleContainerStyle}>
            <div className="Modifier-modal-title-container">
              <h3 className="Modifier-modal-info-content-title">
                {headerTitle.title}
              </h3>

              <button
                className="Modifier-modal-close-icon"
                onClick={handleCloseModal}
                type="button"
              >
                <img alt="" src={closeIcon} />
              </button>
            </div>
          </div>

          <span className="Modifier-modal-info-content-subtitle">
            {headerTitle.subTitle}
          </span>

          {headerImage && (
            <div className="Modifier-modal-info-content-image-container">
              <img
                alt="need to know"
                className="Modifier-modal-info-content-image"
                src={headerImage}
              />
            </div>
          )}

          <div className="Modifier-modal-info-content-rows-container">
            {renderWellnessContent()}

            {renderModalContent()}
          </div>

          <div className="Modifier-modal-bottom-container">
            <button
              onClick={handleCloseModal}
              type="button"
            >
              {t('close')}
            </button>
          </div>
        </div>
      </ScrollableDiv>
    </ModalContainer>
  );
};

export { ModifierModalInfo };
