import { call, put } from 'redux-saga/effects';
import {
  addedToFavorites,
  addToFavoritesFailed,
  addCustomToFavoritesFailed,
  addPointInterestFailed,
  getAllFavoritesAttempt,
  getAllFavorites as getFavorites,
  setFavorites,
  validateZipCodeAttempt,
  validateZipCodeFailed,
  validateZipCodeSuccess,
  vetsSearchCleared,
  vetsSearched,
  removeVetSuccess,
  removeVetFailed,
  hasPrimaryVetChecked,
  primaryVetSaved,
  primaryVetSaveFailed,
} from '../actions';
import {
  apiCallWithApiKey,
  del,
  delApiV2,
  get,
  getApiV2,
  getIDORV2,
  post,
  postApiV2,
} from '../services/api';
import {
  useCustomerApiV1T162824ForSagas,
  usePetCloudCustomerV1T169318ForSagas,
  usePetCloudManualVetFlowForSagas,
  usePetCloudSocialV1T186305ForSagas,
  usePetCloudVetValidationT173886ForSagas,
} from '../services/featureFlagsForSagas';

export function* searchVets(dispatch, { payload }) {
  const { zipCode, query } = payload;

  const queryParms = {
    category: 'veterinary_care',
    latitude: 0,
    location: '',
    longitude: 0,
    radius: 643737,
    searchPetFriendly: true,
    sortBy: 'distance',
    term: query || '',
    zipCode,
  };

  const usePetCloudSocialV1T186305 = yield usePetCloudSocialV1T186305ForSagas();
  const service = usePetCloudSocialV1T186305 ? 'social' : 'api';

  const response = yield call(
    get,
    dispatch,
    `${service}/Places/SearchPlaceWithSummary`,
    queryParms,
  );

  if (response.IsValid) {
    yield put(vetsSearched(response.Data.Places, zipCode));
  } else {
    yield put(vetsSearchCleared());
  }
}

export function* checkIfHasPrimaryVet(dispatch) {
  const isApiVersion2 = yield usePetCloudVetValidationT173886ForSagas();
  const method = isApiVersion2 ? getIDORV2 : get;
  const url = 'api/User/GetUserHasVetAdd';
  const response = yield call(method, dispatch, url);
  yield put(hasPrimaryVetChecked(response.Data));
}

export function* addToFavorites(dispatch, { payload }) {
  const { categoryId, placeId, providerId } = payload;

  const response = yield call(
    post,
    dispatch,
    'api/Favorites/Add',
    {
      categoryId,
      placeId,
      providerId,
    },
  );

  if (response.IsValid) {
    yield put(addedToFavorites(response.Data));
    yield put(getFavorites());
  } else {
    yield put(addToFavoritesFailed());
  }
}

export function* addManuallyVetToFavorites(dispatch, { payload }) {
  const {
    address,
    city,
    email,
    fax,
    phone,
    state,
    vetName,
    zipCode,
  } = payload;

  const url = 'social/VisitedVet/CreateVisitedVetManually';

  const body = {
    address,
    city,
    email,
    fax,
    phone,
    state,
    vetName,
    zipCode,
  };

  const response = yield call(
    postApiV2,
    dispatch,
    url,
    body,
  );

  if (response.success) {
    yield put(addedToFavorites());
    yield put(getFavorites());
    // refreshes vet validation state
    yield checkIfHasPrimaryVet(dispatch);
  } else {
    yield put(addCustomToFavoritesFailed());
  }
}

export function* addCustomToFavorites(dispatch, { payload }) {
  const { categoryId, providerId, name, city, stateId } = payload;

  const usePetCloudCustomerV1T169318 =
    yield usePetCloudCustomerV1T169318ForSagas();

  const service = usePetCloudCustomerV1T169318 ? 'customer' : 'api';
  const addPointInterestURL = `${service}/PointInterest/Add`;
  const addFavoritesURL = 'api/Favorites/Add';

  const body = {
    city,
    name,
    providerId,
    state: stateId,
  };

  let addPointInterestResponse = {};

  if (usePetCloudCustomerV1T169318) {
    addPointInterestResponse = yield call(
      post,
      dispatch,
      addPointInterestURL,
      body,
      dispatch,
    );
  } else {
    addPointInterestResponse = yield call(
      apiCallWithApiKey,
      addPointInterestURL,
      {
        body,
        method: 'POST',
      },
      dispatch,
    );
  }

  if (addPointInterestResponse.IsValid && addPointInterestResponse.Data) {
    const { PointInterestId } = addPointInterestResponse.Data;

    const response = yield call(
      post,
      dispatch,
      addFavoritesURL,
      {
        categoryId,
        placeId: PointInterestId,
        providerId,
      },
    );

    if (response.IsValid) {
      yield put(addedToFavorites(response.Data));
      yield put(getFavorites());
    } else {
      yield put(addCustomToFavoritesFailed(response.Data));
    }
  } else {
    yield put(addPointInterestFailed(addPointInterestResponse.Data));
  }
}

export function* validateZipCode({ payload }) {
  const { zipCode } = payload;

  yield put(validateZipCodeAttempt());

  const response = yield call(
    apiCallWithApiKey,
    `/api/State/ValidateZipcode/${zipCode}`,
  );

  if (response.IsValid && response.Data) {
    yield put(validateZipCodeSuccess());
  } else {
    yield put(validateZipCodeFailed());
  }
}

export function* getAllFavorites(dispatch) {
  yield put(getAllFavoritesAttempt());

  const usePetCloudManualVetFlow =
    yield usePetCloudManualVetFlowForSagas();

  if (usePetCloudManualVetFlow) {
    const response = yield call(
      getApiV2,
      dispatch,
      'social/VisitedVet',
    );

    if (response.success && response.data) {
      yield put(setFavorites(response.data));
    }
  } else {
    const response = yield call(
      get,
      dispatch,
      'api/Favorites/GetAll',
      {
        categoryId: 1,
        // The value provided as userDateTime
        // makes no difference in the response,
        // as long as it's properly formatted as a date.
        // So this is just a random
        // date... Remember remember the fifth of november...
        userDateTime: '1605-11-05 08:50:57',
      },
    );

    if (response.IsValid && response.Data) {
      yield put(setFavorites(response.Data));
    } else {
      yield put(setFavorites([]));
    }
  }
}

export function* removeVet(dispatch, { payload }) {
  const { isVisitedVet, placeId } = payload;

  let isReponseValid = false;

  if (isVisitedVet) {
    const url = `social/VisitedVet/${placeId}`;
    const response = yield call(delApiV2, dispatch, url);
    isReponseValid = response.success;
  } else {
    const url = `api/Favorites/${placeId}`;
    const response = yield call(del, dispatch, url);
    isReponseValid = response.success && response.IsValid;
  }

  if (isReponseValid) {
    yield put(removeVetSuccess());
  } else {
    yield put(removeVetFailed());
  }
}

export function* searchPrimaryVets(dispatch, { payload }) {
  const useCustomerApiV1T162824 = yield useCustomerApiV1T162824ForSagas();

  const { zipCode, query } = payload;

  const queryParms = {
    name: query,
    zipcode: zipCode,
  };

  const url = `api/Quote/Veterinarians?zipcode=${zipCode}&name=${query}`;

  const response = useCustomerApiV1T162824
    ? yield call(
      get,
      dispatch,
      'customer/Vet/Veterinarians',
      queryParms,
    )
    : yield call(apiCallWithApiKey, url);

  yield put(vetsSearched(response.Data || [], zipCode));
}

export function* savePrimaryVet(dispatch, { payload }) {
  const {
    selectedVetId = 0,
    isCustomVet = false,
    vetCompany = '',
    vetCity = '',
    stateId = '',
  } = payload;

  const usePetCloudManualVetFlow =
    yield usePetCloudManualVetFlowForSagas();

  const url = usePetCloudManualVetFlow
    ? 'social/VisitedVet' : 'api/User/AddVet';

  let body = {};
  let response = {};
  let isValidResponse = false;

  if (usePetCloudManualVetFlow) {
    response = yield call(
      postApiV2,
      dispatch,
      url,
      { placeId: selectedVetId },
    );

    isValidResponse = response.success;
  } else {
    if (isCustomVet) {
      body = {
        HasManualVet: isCustomVet,
        StateId: stateId,
        VetCity: vetCity,
        VetCompany: vetCompany,
      };
    } else {
      body = {
        SelectedVetID: selectedVetId,
      };
    }

    response = yield call(post, dispatch, url, body);
    isValidResponse = response.success && response.IsValid;
  }

  if (isValidResponse) {
    yield put(primaryVetSaved());
    yield put(getFavorites());
    // refreshes vet validation state
    yield checkIfHasPrimaryVet(dispatch);
  } else {
    yield put(primaryVetSaveFailed());
  }
}
