import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';

import { doLogin, refreshPets, startupFromCache } from '../../actions';
import {
  useB2CInfo,
  useCustomerV1T176413,
  useMixpanel,
  useQuery,
} from '../../hooks';

import FigoLoadingOverlay from '../common/FigoLoadingOverlay';

import { b2cPolicies, loginRequestScopes } from '../../authConfigB2C';

import { getData, saveData } from '../../services/api';
import {
  IMPERSONATE_EMAIL,
  API_TOKEN,
  USER_INFO_KEY,
} from '../../services/constants';

import './LoginScreen.css';
import { EV_LOGIN, MIXPANEL_LOGIN_EVENTS } from '../../services/mixpanel';
import { createLogAuth } from '../../sagas/session';

const SCOPES = loginRequestScopes;

const LoginScreen = ({ registerToken, isImpersonate = false }) => {
  const store = useSelector(({ session }) => session, shallowEqual);
  const [email, setEmail] = useState('');
  const [mount, setMount] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  const { sendEvent } = useMixpanel();
  const customerV1T176413 = useCustomerV1T176413();

  const loginHintParam = query.get('login_hint');
  const targetEmailParam = query.get('target_email');

  const requestOptions = isImpersonate
    ? {
      ...SCOPES,
      ...b2cPolicies.authorities.impersonation,
      extraQueryParameters: {
        targetEmail: targetEmailParam,
      },
      loginHint: loginHintParam,
    }
    : {
      ...SCOPES,
      ...b2cPolicies.authorities.signUpSignIn,
    };

  const { getAllTokens, logoutB2C } = useB2CInfo();
  const isAuthenticated = useIsAuthenticated();
  const b2cAuthentication =
    useMsalAuthentication(InteractionType.Redirect, requestOptions);

  const { result } = b2cAuthentication;

  const { loading, privateToken } = store;

  useEffect(() => {
    if (email) {
      setEmail(email.trim());
    }
  }, [email]);

  useEffect(() => {
    if (loginHintParam) {
      saveData(IMPERSONATE_EMAIL, loginHintParam);
    }
  }, [loginHintParam]);

  useEffect(() => {
    if (b2cAuthentication.error) {
      const logOutAndLog = async () => {
        await createLogAuth({
          dataDogTag: 'b2cAuthentication',
          error: b2cAuthentication.error,
          mixpanelKey: MIXPANEL_LOGIN_EVENTS.getApiTokens,
          useCustomerAPIV1: customerV1T176413,
        });

        logoutB2C();
      };

      logOutAndLog();
    }
  }, [b2cAuthentication.error, isAuthenticated]);

  const signInUser = useCallback((tokenResponse) => {
    let impersonateToken = null;

    if (tokenResponse) {
      impersonateToken = tokenResponse?.account
        ?.idTokenClaims?.impersonatedUser;
    }

    let hasCustomerId = false;
    let decoded = {};

    try {
      decoded = jwtDecode(tokenResponse.accessToken);
      hasCustomerId = decoded.petCloudCustomerId > 0
        || decoded.impersonatedUserPetCloudCustomerId > 0;
    } catch (error) {
      createLogAuth({
        dataDogTag: 'Error to decode jwt',
        error: error?.message,
        mixpanelKey: MIXPANEL_LOGIN_EVENTS.error,
        useCustomerAPIV1: customerV1T176413,
      });
    }

    if (!hasCustomerId) {
      createLogAuth({
        dataDogTag: 'Invalid customer Id',
        email: decoded?.email,
        error: `petCloudCustomerId: 0 on login ${decoded?.email}, `
          + `Token CustomerID ${tokenResponse.accessToken}`,
        mixpanelKey: MIXPANEL_LOGIN_EVENTS.invalidCustomerId,
        useCustomerAPIV1: customerV1T176413,
      });
    }

    if (tokenResponse && hasCustomerId) {
      dispatch(doLogin({
        b2cToken: tokenResponse.accessToken || tokenResponse.idToken,
        dispatch,
        email,
        isImpersonate: impersonateToken,
      }));
    } else {
      logoutB2C();
    }
  }, [email]);

  useEffect(() => {
    if (result) {
      signInUser(result);
    }
  }, [result]);

  useEffect(() => {
    if (!mount) {
      setMount(true);
    }

    if (isAuthenticated || registerToken || !mount) {
      return;
    }

    sendEvent(EV_LOGIN({
      email: '',
      mixpanelEvent: MIXPANEL_LOGIN_EVENTS.b2cStarted,
    }));
  }, [isAuthenticated, mount]);

  useEffect(() => {
    const sUserInfo = getData(USER_INFO_KEY);

    const startFromCache = async () => {
      await getAllTokens();

      const userInfo = JSON.parse(sUserInfo);
      const token = getData(API_TOKEN);

      await dispatch(startupFromCache({ privateToken: token, userInfo }));
      dispatch(refreshPets());
    };

    if (isAuthenticated && !privateToken && sUserInfo) {
      startFromCache();
    }
  }, [dispatch, isAuthenticated, privateToken]);

  return (
    <>
      <FigoLoadingOverlay visible={!result || loading} />
    </>
  );
};

export { LoginScreen };
