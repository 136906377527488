import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import {
  articlesLoaded,
  articlesLoadFailed,
  getArticles as GET_ARTICLES,
  getPetWisdom as GET_PET_WISDOM,
  getContactBrandDataLoaded,
  getPetWisdomAttempt,
  petWisdomFailed,
  petWisdomLoaded,
} from '../actions';
import { get, getData, getIDORV2 } from '../services/api';
import { useSocialV1T169153ForSagas } from '../services/featureFlagsForSagas';
import { SOCIAL_API_TOKEN } from '../services/constants';

function* getArticles(dispatch, { payload }) {
  const { published, skip } = payload;
  const response = yield call(
    get,
    dispatch,
    `api/contentful/GetBlogContent/${published}/${skip}`,
  );

  if (response.IsValid) {
    yield put(articlesLoaded(response.Data));
  } else {
    yield put(articlesLoadFailed());
  }
}

export function* getPetWisdom(dispatch, { payload }) {
  yield put(getPetWisdomAttempt());
  const { published } = payload;
  const response = yield call(
    get,
    dispatch,
    `api/contentful/GetPetWisdom/${published}`,
  );

  if (response.IsValid && response.Data) {
    yield put(petWisdomLoaded(response.Data));
  } else {
    yield put(petWisdomFailed());
  }
}

function* getContactData({ dispatch, brandInformation, companyId }) {
  const isSocialApi = yield useSocialV1T169153ForSagas();
  const existSocialScope = yield call(getData, SOCIAL_API_TOKEN);

  const api = (isSocialApi && existSocialScope) ? 'social' : 'api';
  const url = `${api}/Contentful/GetCompanyContactUs`;
  const isPublished = process.env.REACT_APP_NAME === 'Prod';
  const params = { companyId, getPublished: isPublished };

  const apiCallMethod = (isSocialApi && existSocialScope) ? get : getIDORV2;

  const response = yield call(apiCallMethod, dispatch, url, params);

  if (response && response.IsValid && response.Data && brandInformation) {
    brandInformation.push({
      ...response.Data,
      companyId,
    });
  }
}

export function* getContactBrandData(dispatch) {
  const brandInformation = [];
  const store = yield select(({
    personalInformation,
  }) => ({ personalInformation }));

  const insuranceCompanies = store.personalInformation.userInfo.Companies;

  const companiesDistinct = Array.from(
    new Set(insuranceCompanies.map(({ Id }) => Id)),
  );

  yield all(companiesDistinct.map((companyId) => call(getContactData, {
    brandInformation,
    companyId,
    dispatch,
  })));

  yield put(getContactBrandDataLoaded(brandInformation));
}

export function* homeSaga(store) {
  yield all([
    takeLatest(GET_ARTICLES, getArticles, store.dispatch),
    takeLatest(GET_PET_WISDOM, getPetWisdom, store.dispatch),
  ]);
}
