import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  checkIfHasPrimaryVet,
  loadNewClaimSteps,
  validateChangeCoverageCleared,
} from '../../actions';

import CancelPolicyModal from './CancelPolicyModal';
import FigoLoadingOverlay from '../common/FigoLoadingOverlay';
import { ChangeCoverageErrorModal }
from './changeCoverage/ChangeCoverageErrorModal';
import { AddPrimaryVet } from '../claims/primaryVet/AddPrimaryVet';
import { FILE_CLAIMBOT } from '../navigation/routes.constants';
import { ModifierModalInfo } from './modifiers/ModifierModalInfo';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';
import { WELLNESS_MODIFIER_ID } from '../../constants';

const PolicyModals = ({
  changeCoverageValidation,
  company = MARKETING_CHANNEL_ID.figo,
  loadingSeeMoreInfo,
  multiModalCategoryId,
  multiModalData,
  multiModalVisible,
  myVetsInfo,
  newClaimStarted,
  onClosePrimaryVetModal,
  onMultiModalClose,
  policyNumber,
  selectedPolicy = null,
  showPrimaryVetModal = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    addVetError,
    checkedPrimaryVet,
    checkingPrimaryVet,
    hasPrimaryVet,
    isSavingVet,
    vetSaved,
  } = myVetsInfo;
  const [cancelPolicyModalVisible, setCancelPolicyModalVisible] =
    useState(false);
  const [
    showChangeCoverageErrorModal,
    setChangeCoverageErrorModal,
  ] = useState(false);

  useEffect(() => {
    const { isSuccess, isValid } = changeCoverageValidation;
    setChangeCoverageErrorModal(isSuccess && !isValid);
  }, [changeCoverageValidation]);

  useEffect(() => {
    if (!checkedPrimaryVet && !checkingPrimaryVet) {
      dispatch(checkIfHasPrimaryVet());
    }
  }, []);

  useEffect(() => {
    if (newClaimStarted) {
      history.push(FILE_CLAIMBOT);
    }
  }, [newClaimStarted]);

  useEffect(() => {
    if (showPrimaryVetModal
      && !isSavingVet
      && !addVetError
      && vetSaved
      && hasPrimaryVet) {
      dispatch(loadNewClaimSteps());
      onMultiModalClose();
    }
  }, [dispatch, isSavingVet, addVetError, hasPrimaryVet, onMultiModalClose]);

  function handleCloseChangeCoverageErrorModal() {
    setChangeCoverageErrorModal(false);
    dispatch(validateChangeCoverageCleared());
  }

  function handleCloseCancelPolicyModal() {
    setCancelPolicyModalVisible(false);
  }

  const multiModalDataSelected = useMemo(() => {
    if (multiModalData && multiModalData.PolicyOptionalDetailsDto) {
      return {
        ...multiModalData,
        PolicyOptionalDetailsDto: multiModalData.PolicyOptionalDetailsDto
          .map((detail) => ({
            ...detail,
            OptionalsBenefit: detail.OptionalsBenefit
              .filter((benefit) => !benefit.GeneralPolicyInfo),
          })),
      };
    }
    return multiModalData;
  }, [multiModalData]);

  const wellnessBenefits = useMemo(() => {
    if (selectedPolicy?.PowerUps) {
      const wellnessModifier = selectedPolicy?.PowerUps
        ?.find((modifier) => (
          modifier.InsuranceModifierId === WELLNESS_MODIFIER_ID
        ));

      return wellnessModifier?.OptionalBenefits;
    }

    return [];
  }, [selectedPolicy]);

  return (
    <>
      <CancelPolicyModal
        onClose={handleCloseCancelPolicyModal}
        visible={cancelPolicyModalVisible}
      />

      <ModifierModalInfo
        company={company}
        initialMenuSelected={multiModalCategoryId}
        isVisible={multiModalVisible}
        multiModalData={multiModalDataSelected}
        onClose={onMultiModalClose}
        policyNumber={policyNumber}
        wellnessBenefits={wellnessBenefits}
      />

      <FigoLoadingOverlay
        visible={loadingSeeMoreInfo}
      />

      <ChangeCoverageErrorModal
        errorMessage={changeCoverageValidation.message}
        handleCloseModal={handleCloseChangeCoverageErrorModal}
        showModal={showChangeCoverageErrorModal}
      />

      {showPrimaryVetModal && (
        <AddPrimaryVet
          onClose={onClosePrimaryVetModal}
        />
      )}
    </>
  );
};

export { PolicyModals };
