import React from 'react';
import { useTranslation } from 'react-i18next';

import { currencyFormat, getDateBasicFormat } from '../../../services/utils';

import { usePetPolicies } from '../../../hooks/usePetPolicies';

import Shimmer from '../../common/Shimmer';

import './css/CoverageOverview.css';
import { CoverageOverviewField } from './CoverageOverviewField';

const CoverageInfoFee = ({
  isCancelled = false,
  selectedPolicy = {},
}) => {
  const petPolicies = usePetPolicies();
  const { loading } = petPolicies;
  const { t } = useTranslation('insuranceScreen');

  const {
    Benefit = '',
    CancellationDate = '',
    Copay = '',
    Deductible = '',
    IncidentLimit = '',
    PetCoverages = '',
    PolicyDisplayNumber = '',
    PolicyEndDate = '',
    PolicyStartDate = '',
    ProductName = '',
    TotalPremium = '',
  } = selectedPolicy;

  function formatDate(date) {
    if (!date) {
      return t('na');
    }

    return getDateBasicFormat(date.split('T')[0], 'MM/DD/YYYY');
  }

  function renderShimmer() {
    return (
      <div className="Flex Flex-column">
        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />
      </div>
    );
  }

  function renderContent() {
    if (isCancelled) {
      const payAmount = TotalPremium
        || TotalPremium === 0
        ? `$${TotalPremium.toFixed(2)}` : t('na');

      return (
        <CoverageInfoFee
          cancellationDate={formatDate(CancellationDate)}
          effectiveDate={formatDate(PolicyStartDate)}
          payAmount={payAmount}
          policyNumber={PolicyDisplayNumber}
        />
      );
    }

    return (
      <>
        <div className="Coverage-overview-column">
          <CoverageOverviewField
            label={t('product')}
            value={ProductName}
          />

          <CoverageOverviewField
            label={t('totalPremium')}
            value={`$${TotalPremium}`}
          />

          <CoverageOverviewField
            label={t('coinsurance')}
            value={`${Copay}%`}
          />
        </div>

        <div className="Coverage-overview-column">
          <CoverageOverviewField
            label={t('policyNumberTitle')}
            value={PolicyDisplayNumber}
          />

          <CoverageOverviewField
            label={t('incidentLimit')}
            value={currencyFormat({ value: IncidentLimit })}
          />

          <CoverageOverviewField
            label={t('coverageLimit')}
            value={currencyFormat({ value: Benefit })}
          />
        </div>

        <div className="Coverage-overview-column">
          <CoverageOverviewField
            label={t('coveragePeriod')}
            value={`${formatDate(PolicyStartDate)} - 
            ${formatDate(PolicyEndDate)}`}
          />

          <CoverageOverviewField
            label={t('deductible')}
            value={currencyFormat({ value: Deductible })}
          />

          <CoverageOverviewField
            label={t('otherPetsOnThisPolicy')}
            value={PetCoverages
              .map((coverages) => coverages.Pet.Name).join(', ')}
          />
        </div>
      </>
    );
  }

  return loading ? renderShimmer() : (
    <>
      <div className="Coverage-overview-policy-info-container">
        {renderContent()}
      </div>
    </>
  );
};

export { CoverageInfoFee };
