import { createActions } from 'redux-actions';

const actions = createActions({
  ADD_CUSTOM_TO_FAVORITES: (data) => data,
  ADD_CUSTOM_TO_FAVORITES_FAILED: (data) => data,
  ADD_MANUALLY_VET_TO_FAVORITES: (data) => data,
  ADD_POINT_INTEREST_FAILED: (data) => data,
  ADD_TO_FAVORITES: (data) => data,
  ADD_TO_FAVORITES_FAILED: () => { },
  ADDED_TO_FAVORITES: (data) => data,
  CHECK_IF_HAS_PRIMARY_VET: () => { },
  CLEAN_MY_VETS_ERRORS: () => { },
  GET_ALL_FAVORITES: () => { },
  GET_ALL_FAVORITES_ATTEMPT: () => { },
  HAS_PRIMARY_VET_CHECKED: (data) => data,
  PRIMARY_VET_SAVE_FAILED: () => { },
  PRIMARY_VET_SAVED: () => { },
  REMOVE_VET: (data) => data,
  REMOVE_VET_FAILED: () => { },
  REMOVE_VET_SUCCESS: () => { },
  SAVE_PRIMARY_VET: (data) => data,
  SEARCH_PRIMARY_VET: (data) => data,
  SEARCH_VETS: (data) => data,
  SET_FAVORITES: (data) => data,
  VALIDATE_ZIP_CODE: (data) => data,
  VALIDATE_ZIP_CODE_ATTEMPT: () => { },
  VALIDATE_ZIP_CODE_FAILED: () => { },
  VALIDATE_ZIP_CODE_SUCCESS: () => { },
  VET_CUSTOM_SAVED_CLEARED: () => { },
  VET_ZIP_CODE_CHANGED: () => { },
  VETS_SEARCH_CLEARED: () => { },
  VETS_SEARCHED: (data) => data,
});

export const {
  addCustomToFavorites,
  addCustomToFavoritesFailed,
  addedToFavorites,
  addManuallyVetToFavorites,
  addPointInterestFailed,
  addToFavorites,
  addToFavoritesFailed,
  checkIfHasPrimaryVet,
  cleanMyVetsErrors,
  getAllFavorites,
  getAllFavoritesAttempt,
  hasPrimaryVetChecked,
  primaryVetSaved,
  primaryVetSaveFailed,
  removeVet,
  removeVetFailed,
  removeVetSuccess,
  savePrimaryVet,
  searchPrimaryVet,
  searchVets,
  setFavorites,
  validateZipCode,
  validateZipCodeAttempt,
  validateZipCodeFailed,
  validateZipCodeSuccess,
  vetCustomSavedCleared,
  vetsSearchCleared,
  vetsSearched,
  vetZipCodeChanged,
} = actions;
